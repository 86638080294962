function strIsEmpty(params) {
  if (typeof params === undefined || params === null || params === '' || params == undefined) {
    return true;
  }
  return false;
}

function distinctArr(arr) {
  return Array.from(new Set(arr))
}

function sortByAsc(prop) {
  return function (a, b) {
    var v1 = a[prop];
    var v2 = b[prop];
    return v1 - v2;
  };
}

function sortByDesc(prop) {
  return function (a, b) {
    var v1 = a[prop];
    var v2 = b[prop];
    return v2 - v1;
  };
}

function requestDingAuthCode(_this) {
  return new Promise((resolve, reject) => {
    _this.$dd.runtime.permission.requestAuthCode({
      corpId: "dinged4b513e43cdbf9af2c783f7214b6d69",
      onSuccess: function (res) {
        //res.code
        resolve(res.code);
      },
    }).catch((error) => {
      //not in dingtalk
      resolve(null);
    });
  });
}

//Scale
function setScale() {
  window.onload = function () {
    document.addEventListener('touchstart', function (event) {
      if (event.touches.length > 1) {
        event.preventDefault()
      }
    })
    document.addEventListener('gesturestart', function (event) {
      event.preventDefault()
    })
  }
}

export {
  strIsEmpty,
  distinctArr,
  sortByAsc,
  sortByDesc,
  requestDingAuthCode,
  setScale
}