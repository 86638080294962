<template>
  <div class="base" style="width: 100%; height: 100%">
    <transition name="el-fade-in-linear">
      <div class="whole_wrap" v-show="show.wholeShow">
        <div class="head_wrap" v-show="show.qsDone">
          <div class="top_text_content">
            <div class="title_content">
              <p>{{ $t("bigfive.askform_title") }}</p>
            </div>
          </div>
          <div class="progress_content">
            <span
              style="color: #666666; font-weight: normal; font-size: 16px"
              >{{
                $t("bigfive.toptext_1") +
                (60 - _pages._cursor) +
                $t("bigfive.toptext_2")
              }}</span
            >
            <div class="tips_text">
              <!-- {{ $t("bigfive.top_tip_text") }} -->
              <!-- <el-alert
                :title="$t('bigfive.top_tip_text')"
                type="warning"
                show-icon
                effect="dark"
                :closable="false"
              /> -->
            </div>
            <el-progress
              :percentage="Number(_pages.qsCurrentPercent.toFixed(0))"
              :text-inside="true"
              :color="_pages.autoColors"
              :stroke-width="15"
            ></el-progress>
          </div>
        </div>

        <div class="body_wrap" v-if="show.bodyShow">
          <div class="question_content" v-show="show.qsDone">
            <div class="question_title">
              <span v-if="_pages.questions.length > 0"
                >{{ _pages._cursor + 1 + "." }}
                <span v-if="_pages.lang == 'ch'">{{
                  _pages.questions[_pages._cursor].content
                }}</span>
                <span v-else>{{
                  _pages.questions[_pages._cursor].content_en
                }}</span>
              </span>
            </div>
            <div class="option_title">
              <div
                v-if="_pages.questions.length > 0"
                class="options_parent_content"
              >
                <el-radio-group v-model="_forms.scoreList[_pages._cursor]">
                  <div
                    v-if="_pages.questions[_pages._cursor].opt_type == 0"
                    class="options_content"
                  >
                    <div
                      v-for="(option, index) in _pages._opt_asc"
                      :key="index"
                      :index="index"
                    >
                      <p>
                        <el-radio
                          size="large"
                          @change="toNextQuestion(index)"
                          :label="
                            option.score +
                            '-' +
                            _pages.questions[_pages._cursor].group
                          "
                          ><span class="ops_titile">{{
                            option.title
                          }}</span></el-radio
                        >
                      </p>
                    </div>
                  </div>
                  <div v-else class="options_content">
                    <div
                      v-for="(option, index) in _pages._opt_desc"
                      :key="index"
                      :index="index"
                    >
                      <p>
                        <el-radio
                          size="large"
                          @change="toNextQuestion(index)"
                          :label="
                            option.score +
                            '-' +
                            _pages.questions[_pages._cursor].group
                          "
                        >
                          <span class="ops_titile">{{ option.title }}</span>
                        </el-radio>
                      </p>
                    </div>
                  </div>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="explain_content" v-show="show.explainUs">
            <div class="result_radar_content">
              <canvas id="result_radar_chart"></canvas>
            </div>
            <div class="explain_text_content" v-if="_pages.explains.length > 0">
              <div
                v-for="(item, index) in _pages.explains"
                :key="index"
                :index="index"
              >
                <span>{{ item.title }}</span>
                <p>{{ item.content[0] }}</p>
                <p>{{ item.content[1] }}</p>
                <p>{{ item.content[2] }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="foot_wrap">
          <div class="ask_info_content">
            <el-dialog
              v-model="_pages.askDialogVisible"
              :title="$t('bigfive.title_ask_info')"
              destroy-on-close
              center
              width="80%"
              :close-on-click-modal="false"
              :close-on-press-escape="false"
              :show-close="false"
            >
              <div class="ask_img_content">
                <span class="image_item">
                  <el-image :src="require('@/assets/images/bigfive_c1.png')">
                    <template #error>
                      <div class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div> </template
                  ></el-image>
                  <p>{{ $t("bigfive.top_tip_text") }}</p>
                </span>

                <span class="image_item">
                  <el-image :src="require('@/assets/images/bigfive_c2.png')">
                    <template #error>
                      <div class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div> </template
                  ></el-image>
                  <p>{{ $t("bigfive.top_tip_text2") }}</p>
                </span>

                <span class="image_item">
                  <el-image :src="require('@/assets/images/bigfive_c3.png')">
                    <template #error>
                      <div class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div> </template
                  ></el-image>
                  <p>{{ $t("bigfive.top_tip_text3") }}</p>
                </span>
              </div>

              <div class="ask_form_content">
                <!-- <div style="margin-bottom: 20px">
                  {{ $t("bigfive.content_ask_info") }}
                </div> -->
                <el-form size="small" style="width: 20vw; margin: 0 auto">
                  <el-form-item :label="$t('bigfive.label_fullname')">
                    <el-input
                      maxlength="20"
                      show-word-limit
                      v-model="_pages.fullname"
                    ></el-input>
                  </el-form-item>

                  <el-form-item :label="$t('bigfive.label_post')">
                    <el-input
                      maxlength="20"
                      show-word-limit
                      v-model="_pages.post"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>

              <template #footer>
                <span class="dialog-footer">
                  <el-button type="success" @click="saveAskmation">{{
                    $t("common.btn_message_confirm")
                  }}</el-button>
                </span>
              </template>
            </el-dialog>
            <div class="keys_wrap" v-if="show.keysShow">
              <el-dialog
                v-model="_pages.keyDVisble"
                :title="$t('text.key_title')"
                width="50vw"
                destroy-on-close
                center
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :show-close="false"
              >
                <div>
                  <el-input
                    v-model="_pages.userkey"
                    maxlength="8"
                    show-word-limit
                    size="medium"
                  ></el-input>
                </div>
                <template #footer>
                  <span class="dialog-footer">
                    <el-button type="success" @click="verifyKey">{{
                      $t("common.btn_message_confirm")
                    }}</el-button>
                  </span>
                </template>
              </el-dialog>
            </div>
          </div>
          <div class="tool_content">
            <div class="_redo">
              <el-button
                type="danger"
                @click="redo()"
                plain
                round
                size="small"
                v-show="show.qsDone"
                >{{ $t("bigfive.btn_redo") }}</el-button
              >
            </div>
          </div>
          <div class="ifi_texts">
            <span>{{ $t("text.text_powered_by") }}</span>
          </div>
          <!-- <div v-if="false">
            指标意义
            大五人格特质模型将人格分为5个方面，包括神经质、外倾性、开放性、友善性和严谨性，分别由5个分测验测量。神经质得分越高，情绪越冲动，敏感，焦虑；外倾性得分越高，越合群，热情，积极；开放性得分越高，越易幻想，爱行动，有审美情趣；友善性得分越高，越谦虚，很温和，容易信任他人；严谨性得分越高，越守秩序，很负责任，能自我约束。
          </div> -->
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Chart from 'chart.js'
import * as utilsjs from '@/assets/js/utils'
import * as mountederjs from '@/assets/js/mounteder'
export default {
  data() {
    return {
      show: {
        keysShow: true,
        bodyShow: false,
        wholeShow: false,
        //TODO 4
        qsDone: true,
        explainUs: false
      },
      _pages: {
        lang: '',
        questions: [],
        qsEveryPercent: 0,
        qsCurrentPercent: 0,
        _cursor: 0,
        _opt_asc: [],
        _opt_desc: [],
        autoColors: [
          { color: '#f56c6c', percentage: 20 },
          { color: '#e6a23c', percentage: 40 },
          { color: '#5cb87a', percentage: 60 },
          { color: '#1989fa', percentage: 99 },
          { color: '#67c23a', percentage: 100 },
        ],
        explains: [],
        fullname: '',
        post: '',
        _options: [],
        askDialogVisible: true,
        userkey: '',
        keyDVisble: true
      },
      _forms: {
        scoreList: [],
        resultScores: {
          //TODO 3
          // _scoreN: 52,
          // _scoreE: 48,
          // _scoreO: 45,
          // _scoreA: 41,
          // _scoreC: 20
          _scoreN: 0,
          _scoreE: 0,
          _scoreO: 0,
          _scoreA: 0,
          _scoreC: 0
        }
      }
    };
  },
  mounted() {
    if (utilsjs.strIsEmpty(this.$route.query.q)) {
      this.$i18n.locale = 'en';
    } else {
      this.$i18n.locale = this.$route.query.q;
    }

    document.title = this.$t('title.bigfive');
    sessionStorage.setItem('lang', this.$i18n.locale);
    this._pages.lang = sessionStorage.getItem('lang');

    this.queryQs();
    this.initOptions();
    this.initExplains();
    utilsjs.setScale();

    //TODO 1
    // this.toMountResult();
  },
  methods: {
    redo() {
      location.reload();
    },
    saveAskmation() {
      if (utilsjs.strIsEmpty(this._pages.fullname) || utilsjs.strIsEmpty(this._pages.post)) {
        this.$notify.warning({
          title: this.$t("notify.title_notification"),
          message: this.$t('bigfive.notify_ask_unfinished')
        });
        return;
      }
      this._pages.askDialogVisible = false;
    },
    toNextQuestion(index) {
      this._pages._options.push(Number(index + 1) + '-');
      let scoreCount = 0;

      for (let qs in this._forms.scoreList) {
        if (utilsjs.strIsEmpty(qs)) {
          continue;
        } else {
          scoreCount++;
        }
      }

      if (this._pages._cursor >= 59) {
        // setTimeout(() => {

        // }, 100);
        this._pages.qsCurrentPercent = scoreCount * this._pages.qsEveryPercent;

        this.show.qsDone = false;

        this.toMountResult();
        return;
      }

      this._pages.qsCurrentPercent = scoreCount * this._pages.qsEveryPercent;
      this._pages._cursor++;
      // setTimeout(() => {  }, 200);
    },
    toMountResult() {
      //TODO 2

      if (this._forms.scoreList.length != 60) {
        this.$notify.error({
          title: this.$t("notify.title_err_type1"),
          message: this.$t('notify.content_ill')
        });
        return;
      }

      //exams
      for (let item of this._forms.scoreList) {
        if (item.split('-')[1] == 'N') {
          this._forms.resultScores._scoreN += Number(item.split('-')[0]);
        } else if (item.split('-')[1] == 'E') {
          this._forms.resultScores._scoreE += Number(item.split('-')[0]);
        } else if (item.split('-')[1] == 'O') {
          this._forms.resultScores._scoreO += Number(item.split('-')[0]);
        } else if (item.split('-')[1] == 'A') {
          this._forms.resultScores._scoreA += Number(item.split('-')[0]);
        } else if (item.split('-')[1] == 'C') {
          this._forms.resultScores._scoreC += Number(item.split('-')[0]);
        }
      }

      this.createNewSurveyInfo();

      //radar
      setTimeout(() => {
        this.$nextTick(function () {
          var ctx_result_radar = document.getElementById("result_radar_chart");
          Chart.defaults.global.defaultFontSize = 18;
          Chart.defaults.global.defaultFontColor = 'rgba(255, 69, 0, 0.68)';

          var result_radar_chart_init = new Chart(ctx_result_radar, {
            type: "radar",
            // scaleStartValue: 0,
            // scaleSteps: 5,
            // scaleStepWidth: 1,
            data: {
              labels: [
                this.$t('bigfive.targetN') + ':' + this._forms.resultScores._scoreN,
                this.$t('bigfive.targetE') + ':' + this._forms.resultScores._scoreE,
                this.$t('bigfive.targetO') + ':' + this._forms.resultScores._scoreO,
                this.$t('bigfive.targetA') + ':' + this._forms.resultScores._scoreA,
                this.$t('bigfive.targetC') + ':' + this._forms.resultScores._scoreC],
              datasets: [
                {
                  label: '',
                  // backgroundColor: "rgba(103,194,58)",
                  borderColor: "rgba(103,194,58)",
                  pointBackgroundColor: "rgba(103,194,58)",
                  borderWidth: 2,
                  pointStrokeColor: "#fff",
                  pointStyle: "rot",
                  data: [
                    this._forms.resultScores._scoreN,
                    this._forms.resultScores._scoreE,
                    this._forms.resultScores._scoreO,
                    this._forms.resultScores._scoreA,
                    this._forms.resultScores._scoreC
                  ],
                  cubicInterpolationMode: "monotone",
                  spanGaps: "true",
                }
              ],
            },
            options: {
              scale: {
                pointLabels: {
                  fontSize: 14
                },
                ticks: {
                  beginAtZero: true,
                  max: 60.00
                }
              },
              legend: {
                display: false,
                labels: {

                }
              },
              elements: {}
            }
          });
        });


        this.show.explainUs = true;
      }, 100);

    },
    queryQs() {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/survey/queryBigFiveQs'
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this._pages.questions = response.data.data;
            if (this._pages.questions.length > 0) {
              this._pages.qsEveryPercent = 100 / this._pages.questions.length;
            } else {
              this.$notify.error({
                title: this.$t("notify.title_server"),
                message: this.$t('notify.content_refused')
              });
              return;
            }

            setTimeout(() => { this.show.wholeShow = true }, 1);

            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    createNewSurveyInfo() {
      if (utilsjs.strIsEmpty(this._pages.userkey)) {
        //irr
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_ill')
        });
        return;
      }

      let _key = {};
      _key.pwd = this._pages.userkey;
      _key.way = 2;

      let info = {};
      info.name = this._pages.fullname;
      info.post = this._pages.post;
      info.options = (JSON.parse(JSON.stringify(this._pages._options))).toString();

      info.score1 = this._forms.resultScores._scoreN;
      info.score2 = this._forms.resultScores._scoreE;
      info.score3 = this._forms.resultScores._scoreO;
      info.score4 = this._forms.resultScores._scoreA;
      info.score5 = this._forms.resultScores._scoreC;

      info.key = _key;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/survey/uponBFInfo',
        data: info
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {

            return;
          } else if (response.data.code == '701') {
            this.$notify.error({
              title: this.$t("notify.title_err_type1"),
              message: this.$t('notify.content_ill')
            });

            setTimeout(() => {
              this.show.explainUs = false;
            }, 500);
            return;

          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    initExplains() {
      let obj1 = {};
      let arr1 = [];
      obj1.title = this.$t("bigfive.explain_title_1");
      arr1.push(this.$t("bigfive.explain_content_1_1"));
      arr1.push(this.$t("bigfive.explain_content_1_2"));
      arr1.push(this.$t("bigfive.explain_content_1_3"));
      obj1.content = JSON.parse(JSON.stringify(arr1));

      let obj2 = {};
      let arr2 = [];
      obj2.title = this.$t("bigfive.explain_title_2");
      arr2.push(this.$t("bigfive.explain_content_2_1"));
      arr2.push(this.$t("bigfive.explain_content_2_2"));
      arr2.push(this.$t("bigfive.explain_content_2_3"));
      obj2.content = JSON.parse(JSON.stringify(arr2));

      let obj3 = {};
      let arr3 = [];
      obj3.title = this.$t("bigfive.explain_title_3");
      arr3.push(this.$t("bigfive.explain_content_3_1"));
      arr3.push(this.$t("bigfive.explain_content_3_2"));
      arr3.push(this.$t("bigfive.explain_content_3_3"));
      obj3.content = JSON.parse(JSON.stringify(arr3));

      let obj4 = {};
      let arr4 = [];
      obj4.title = this.$t("bigfive.explain_title_4");
      arr4.push(this.$t("bigfive.explain_content_4_1"));
      arr4.push(this.$t("bigfive.explain_content_4_2"));
      arr4.push(this.$t("bigfive.explain_content_4_3"));
      obj4.content = JSON.parse(JSON.stringify(arr4));

      let obj5 = {};
      let arr5 = [];
      obj5.title = this.$t("bigfive.explain_title_5");
      arr5.push(this.$t("bigfive.explain_content_5_1"));
      arr5.push(this.$t("bigfive.explain_content_5_2"));
      arr5.push(this.$t("bigfive.explain_content_5_3"));
      obj5.content = JSON.parse(JSON.stringify(arr5));

      this._pages.explains.push(obj1, obj2, obj3, obj4, obj5);
    },
    initOptions() {
      let obj1 = {};
      obj1.score = 1;
      obj1.title = this.$t("bigfive.option_1");
      let obj2 = {};
      obj2.score = 2;
      obj2.title = this.$t("bigfive.option_2");
      let obj3 = {};
      obj3.score = 3;
      obj3.title = this.$t("bigfive.option_3");
      let obj4 = {};
      obj4.score = 4;
      obj4.title = this.$t("bigfive.option_4");
      let obj5 = {};
      obj5.score = 5;
      obj5.title = this.$t("bigfive.option_5");
      this._pages._opt_asc.push(obj1, obj2, obj3, obj4, obj5);

      let _obj1 = {};
      _obj1.score = 5;
      _obj1.title = this.$t("bigfive.option_1");
      let _obj2 = {};
      _obj2.score = 4;
      _obj2.title = this.$t("bigfive.option_2");
      let _obj3 = {};
      _obj3.score = 3;
      _obj3.title = this.$t("bigfive.option_3");
      let _obj4 = {};
      _obj4.score = 2;
      _obj4.title = this.$t("bigfive.option_4");
      let _obj5 = {};
      _obj5.score = 1;
      _obj5.title = this.$t("bigfive.option_5");
      this._pages._opt_desc.push(_obj1, _obj2, _obj3, _obj4, _obj5);
    },
    verifyKey() {
      if (utilsjs.strIsEmpty(this._pages.userkey)) {
        return;
      }
      if (this._pages.userkey.length != 6) {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_key_err')
        });
        return;
      }
      let _key = {};
      _key.pwd = this._pages.userkey;
      this.$axios({
        method: 'post',
        url: this.$api + '/api/survey/verifyKey',
        data: _key
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.show.keysShow = false;
            this.show.bodyShow = true;
            return;
          } else if (response.data.code == '210') {
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_key_err')
            });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    }
  }
}
</script>

<style scoped lang="scss">
.whole_wrap {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
}
.head_wrap {
  width: 100%;
  margin-top: 15vh;
}
.title_content p {
  font-size: 35px;
  font-weight: bold;
}
.progress_content {
  width: 60vw;
  text-align: left;
  margin-top: 50px;
}
.progress_content span {
  font-size: 16px;
  color: #000;
}
.progress_content .el-progress {
  margin-top: 20px;
}
.tips_text {
  font-size: 15px;
  color: #000;
  margin-top: 10px;
}
.body_warp {
  width: 100%;
  position: relative;
}
.question_content {
  margin-top: 30px;
  margin: 0 auto;
}
.question_title {
  width: 100vw;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
}
.question_title span {
  font-family: "Microsoft YaHei", "微软雅黑";
  font-size: 20px;
  color: #000;
}
.progress_content,
.question_title,
.option_title {
  margin-left: 20vw;
}
.options_parent_content {
  width: 100%;
}
.options_content {
  line-height: 50px;
  text-align: left;
}
.ops_titile {
  font-size: 17px;
  font-family: "Microsoft YaHei", "微软雅黑";
}
.el-radio-group {
  width: 100%;
}
._redo {
  position: fixed;
  top: 2vh;
  left: 2vw;
}
.ifi_texts {
  position: fixed;
  bottom: 20px;
  right: 5vw;
  font-size: 15px;
  user-select: none;
  color: #67a23c;
}
.explain_content {
  display: block;
}
.result_radar_content {
  // float: left;
  margin-top: 5vh;
  margin-right: 5vw;
  // height: 135vh;
}
.explain_text_content {
  padding-top: 13vh;
  padding-bottom: 5vh;
  text-align: left;
  margin: 0 auto;
  width: 80%;
}
.explain_text_content span {
  font-size: 20px;
  font-weight: bold;
  font-family: "Microsoft YaHei", "微软雅黑";
  line-height: 4vh;
}
.explain_text_content p {
  line-height: 3vh;
  font-size: 17px;
}
#result_radar_chart {
  width: 40vw;
  height: 40vh;
}
.ask_info_content {
  margin-top: -15vh;
}
.image_item {
  display: block;
}
.ask_form_content {
  margin-top: 5vh;
}
.ask_img_content {
  display: flex;
  justify-content: center;
}
.ask_img_content,
.ask_form_content {
  text-align: center;
}
.image_item {
  width: 25vh;
  word-break: break-word;
  margin-left: 1vw;
}
</style>