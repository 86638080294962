function evaluateM(_this) {
    _this.$i18n.locale = sessionStorage.getItem("lang");
    if (utilsjs.strIsEmpty(_this.pageData.username)) {
        return false;
    }
    if (_this.pageData.userinfo.type != 'evm') {
        return false;
    }
    return true;
}

function evaluateN(_this) {
    _this.$i18n.locale = sessionStorage.getItem("lang");
    if (utilsjs.strIsEmpty(_this.pageData.username)) {
        return false;
    }
    if (_this.pageData.userinfo.type != 'evn') {
        return false;
    }
    return true;
}

import * as utilsjs from '@/assets/js/utils'

export {
    evaluateM,
    evaluateN
}