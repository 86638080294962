<template>
  <div class="base" style="width: 100%; height: 100%">
    <transition name="el-fade-in-linear">
      <div class="whole_wrap" v-show="show.wholeShow">
        <div class="head_wrap">
          <div class="unfinished_content">
            <span
              v-if="
                formData.answer.scoreList.length == 0 &&
                pageData._incompletely_indexs.length == 0
              "
              style="color: #409eff"
              >{{ $t("text.text_to_be_started") }}</span
            >
            <span
              v-else-if="pageData._incompletely_indexs.length != 0"
              style="color: #f56c6c"
              >{{
                $t("askform.text_unfinished_qs") + pageData._incompletely_indexs
              }}</span
            >
            <span v-else style="color: #67c23a">{{
              $t("text.text_complete")
            }}</span>
          </div>
          <!-- <div class="object_content">
            {{ $t("askform.current_object") }}
            <span v-if="pageData.lang == 'ch'">{{
              pageData.activity.fullname_obj
            }}</span>
            <span v-else>{{ pageData.activity.fullname_obj }}</span>
          </div> -->
          <div class="progress_content">
            <el-progress
              :percentage="Number(pageData.currentPercent.toFixed(0))"
              type="circle"
              :color="pageData.autoColors"
              :width="65"
            >
              <template #default="{ percentage }">
                <span class="percentage-value">{{ percentage }}%</span>
              </template>
            </el-progress>
          </div>
        </div>
        <div class="body_wrap">
          <div class="title_content">
            <span v-if="pageData.lang == 'ch'"
              >{{ pageData.activity.name }}({{
                pageData.activity.fullname_obj
              }})</span
            >
            <span v-else
              >{{ pageData.activity.name_en }}({{
                pageData.activity.fullname_obj
              }})</span
            >
          </div>
          <div class="exam_content">
            <div class="question_content">
              <!-- <el-card shadow="hover"> -->
              <div
                class="questions_wraps"
                v-for="(question, index) in pageData.questionnaire
                  .questionsList"
                :key="index"
                :index="index"
              >
                <span
                  >{{ index + 1 }}.<span v-if="pageData.lang == 'ch'">{{
                    question.content
                  }}</span
                  ><span v-else>{{ question.content_en }}</span></span
                >
                <div v-if="question.type === 0" class="radio_content">
                  <el-radio-group v-model="formData.answer.scoreList[index]">
                    <el-radio
                      @change="updateQsProgress"
                      class="answer_radio_item"
                      v-for="(
                        option, sortIndex
                      ) in pageData.questionOptionsList"
                      :key="sortIndex"
                      :index="sortIndex"
                      :label="question.id + '-' + option.score"
                      ><span v-if="pageData.lang == 'ch'" class="answer_span">{{
                        option.name
                      }}</span
                      ><span v-else class="answer_span">{{
                        option.name_en
                      }}</span></el-radio
                    >
                  </el-radio-group>
                </div>
                <div v-else-if="question.type === 1" class="textarea_content">
                  <el-input
                    type="textarea"
                    v-model="formData.answer.subjectiveList[index]"
                    :placeholder="$t('text.placeholder')"
                    maxlength="2000"
                    :rows="5"
                    show-word-limit
                    @change="updateQsProgress"
                  ></el-input>
                </div>
              </div>
              <div class="btn_content">
                <el-button
                  @click="postAskForm"
                  type="success"
                  :loading="pageData.postAskFormLoading"
                  :disabled="
                    formData.answer.scoreList.length == 0 ||
                    pageData._incompletely_indexs.length != 0
                  "
                  >{{ $t("askform.btn_submit_askform") }}</el-button
                >
              </div>
              <!-- </el-card> -->
            </div>
          </div>
        </div>
        <div class="foot_wrap">
          <el-backtop :right="50"></el-backtop>
          <div class="ifi_texts">
            <span>{{ $t("text.text_powered_by") }}</span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as utilsjs from '@/assets/js/utils'
import * as mountederjs from '@/assets/js/mounteder'
export default {
  data() {
    return {
      show: {
        wholeShow: false
      },
      pageData: {
        username: '',
        userinfo: {},
        lang: '',
        activity: {
          name: '',
          name_en: '',
          username_obj: '',
          fullname_obj: '',
          englishname_obj: '',
          questionnaire_id: '',
          relations_id: ''
        },
        questionnaire: {},
        questionOptionsList: [],
        postAskFormLoading: false,
        qusEveryPercent: 0,
        currentPercent: 0,
        autoColors: [
          { color: '#f56c6c', percentage: 20 },
          { color: '#e6a23c', percentage: 40 },
          { color: '#5cb87a', percentage: 60 },
          { color: '#1989fa', percentage: 99 },
          { color: '#67c23a', percentage: 100 },
        ],
        _completely_indexs: [],
        _incompletely_indexs: [],
        qsIndexList: []
      },
      formData: {
        answer: {
          scoreList: [],
          subjectiveList: []
        }
      }
    };
  },
  mounted() {
    this.pageData.username = sessionStorage.getItem('username');
    this.pageData.userinfo = JSON.parse(sessionStorage.getItem(this.pageData.username));
    this.pageData.lang = sessionStorage.getItem('lang');
    this.pageData.activity.name = this.$route.query.actname;
    this.pageData.activity.name_en = this.$route.query.actname_en;
    this.pageData.activity.username_obj = this.$route.query.username_obj;
    this.pageData.activity.fullname_obj = this.$route.query.fullname_obj;
    this.pageData.activity.englishname_obj = this.$route.query.englishname_obj;
    this.pageData.activity.questionnaire_id = this.$route.query.questionnaire_id;
    this.pageData.activity.relations_id = this.$route.query.relations_id;
    if (this.pageData.lang == 'ch') {
      document.title = this.pageData.activity.name;
    } else {
      document.title = this.pageData.activity.name_en;
    }
    if (mountederjs.evaluateN(this)) {
      this.queryQuestionOptions();
      setTimeout(() => { this.show.wholeShow = true }, 1);
    } else {
      this.$router.push({ name: 'Login' });
    }
  },
  methods: {
    updateQsProgress(val) {
      this.pageData._incompletely_indexs = [];
      let scoreCount = 0;
      let subjectiveCount = 0;
      for (let scoreIndex in this.formData.answer.scoreList) {
        if (utilsjs.strIsEmpty(this.formData.answer.scoreList[scoreIndex])) {
          continue;
        } else {
          this.pageData._completely_indexs.push(Number(scoreIndex) + 1);
          scoreCount++;
        }
      }

      for (let subjectiveIndex in this.formData.answer.subjectiveList) {
        if (utilsjs.strIsEmpty(this.formData.answer.subjectiveList[subjectiveIndex])) {
          continue;
        } else {
          this.pageData._completely_indexs.push(Number(subjectiveIndex) + 1);
          subjectiveCount++;
        }
      }

      //--
      let set1 = new Set(this.pageData.qsIndexList);
      let set2 = new Set(this.pageData._completely_indexs);
      for (let item of set1) {
        if (!set2.has(item)) {
          this.pageData._incompletely_indexs.push(item);
        }
      }

      let answerCount = scoreCount + subjectiveCount;
      this.pageData.currentPercent = answerCount * this.pageData.qusEveryPercent;

    },
    queryQuestionOptions() {
      let params = {
        user: {}
      };
      params.user.username = this.pageData.username;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/quiz/queryQuestionOptions',
        data: params
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {

            this.pageData.questionOptionsList = response.data.data;

            this.queryAskForm();

            return;
          } else if (response.data.code == '210') {
            this.$notify.warning({
              title: this.$t("notify.title_server"),
              message: this.$t('askform.notify_questionoptions_null')
            });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    queryAskForm() {
      let questionnaire = {};
      questionnaire.id = this.pageData.activity.questionnaire_id;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/quiz/queryAskForm',
        data: questionnaire
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {

            this.pageData.questionnaire = response.data.data;

            if (this.pageData.questionnaire.questionsList.length > 0) {
              this.pageData.qusEveryPercent = 100 / this.pageData.questionnaire.questionsList.length;
            } else {
              this.$notify.error({
                title: this.$t("notify.title_server"),
                message: this.$t('notify.content_refused')
              });
              return;
            }


            for (let index in this.pageData.questionnaire.questionsList) {
              this.pageData.qsIndexList.push(Number(index) + 1);
            }

            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    postAskForm() {
      this.$confirm(this.$t("askform.text_submit_questionnaire_body"), this.$t("text.text_confirm_title"), {
        confirmButtonText: this.$t("common.btn_message_confirm"),
        cancelButtonText: this.$t("common.btn_message_cancel"),
        type: 'warning'
      }).then(() => {
        this.pageData.postAskFormLoading = true;

        let scoreCount = 0;
        let subjectiveCount = 0;
        for (let score of this.formData.answer.scoreList) {
          if (utilsjs.strIsEmpty(score)) {
            continue;
          } else {
            scoreCount++;
          }
        }
        for (let subjective of this.formData.answer.subjectiveList) {
          if (utilsjs.strIsEmpty(subjective)) {
            continue;
          } else {
            subjectiveCount++;
          }
        }
        let answerCount = scoreCount + subjectiveCount;
        this.postToSubmitQuestionnaire(answerCount);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t("text.text_canceled")
        });
      });
    },
    postToSubmitQuestionnaire(answerCount) {
      if (answerCount < this.pageData.questionnaire.questionsList.length) {
        this.$notify.error({
          title: this.$t("notify.title_err_type1"),
          message: this.$t('askform.notify_incomplete_answer')
        });
        this.pageData.postAskFormLoading = false;
        return;
      }

      //set values
      let requestAnswer = {};
      let selectorList = [];
      let resultsList = [];
      for (let index in this.formData.answer.scoreList) {
        if (utilsjs.strIsEmpty(this.formData.answer.scoreList[index])) {
          continue;
        } else {
          let obj = {};
          obj.username = this.pageData.activity.username_obj;
          obj.sort = index;
          obj.relations_id = this.pageData.activity.relations_id;
          obj.question_id = this.formData.answer.scoreList[index].split('-')[0];
          obj.score = Number(this.formData.answer.scoreList[index].split('-')[1]);
          obj.creator = this.pageData.username;
          selectorList.push(obj);
        }
      }
      for (let index in this.formData.answer.subjectiveList) {
        if (utilsjs.strIsEmpty(this.formData.answer.subjectiveList[index])) {
          continue;
        } else {
          let obj = {};
          obj.username = this.pageData.activity.username_obj;
          obj.sort = index;
          obj.relations_id = this.pageData.activity.relations_id;
          obj.question_id = this.pageData.questionnaire.questionsList[index].id
          obj.explain = this.formData.answer.subjectiveList[index];
          obj.creator = this.pageData.username;
          resultsList.push(obj);
        }
      }
      requestAnswer.selectorList = JSON.parse(JSON.stringify(selectorList));
      requestAnswer.resultsList = JSON.parse(JSON.stringify(resultsList));
      requestAnswer.isreport = 1;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/quiz/submitAnswer',
        data: requestAnswer
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.$ElMessage.success({
              message: this.$t("text.message_saved"),
              type: 'success'
            });

            this.pageData.postAskFormLoading = false;

            setTimeout(() => {
              this.$router.push({ name: 'Activities' });
            }, 1000)

            return;
          } else if (response.data.code == '290') {

            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('askform.notify_warn_apart')
            });
            this.pageData.postAskFormLoading = false;
            return;
          } else if (response.data.code == '701') {
            this.$notify.error({
              title: this.$t("notify.title_err_type_ill"),
              message: this.$t('notify.content_ill')
            });
            this.pageData.postAskFormLoading = false;

            setTimeout(() => {
              this.$router.push({ name: 'Activities' });
            }, 1000)
            return;
          } else {
            //code = 700 and more
            this.pageData.postAskFormLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.pageData.postAskFormLoading = false;
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.pageData.postAskFormLoading = false;
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    }
  }
}
</script>

<style scoped lang="scss">
.object_content {
  position: fixed;
  font-size: 15px;
  color: #e6a23c;
  padding: 30px;
  top: -20px;
  right: 20px;
}
.progress_content {
  position: fixed;
  left: 40px;
  top: 65px;
}
.unfinished_content {
  position: fixed;
  top: 10px;
  left: 5px;
  font-size: 15px;
  width: 150px;
}
.title_content span {
  color: #606266;
  font-size: 27px;
  margin-top: 80px;
  margin-bottom: 50px;
}
.body_wrap {
  margin-top: 80px;
}
.exam_content {
  width: 60%;
  margin: 0 auto;
  margin-top: 30px;
}
.questions_wraps {
  font-size: 20px;
  text-align: left;
  color: #303133;
  margin-bottom: 50px;
  margin-left: 50px;
  margin-top: 10px;
  font-family: "Microsoft YaHei", "微软雅黑";
}
.radio_content {
  margin-top: 15px;
  font-family: "Microsoft YaHei", "微软雅黑";
  // margin-left: 10px;
}
.textarea_content {
  margin-top: 10px;
}
.answer_radio_item {
  width: 900px;
  line-height: 40px;
}
.answer_span {
  color: #909399;
  font-family: "Microsoft YaHei", "微软雅黑";
  font-size: 17px;
}
.btn_content {
  margin-top: 100px;
  float: right;
}
.btn_content .el-button {
  margin-bottom: 50px;
}
.ifi_texts {
  position: fixed;
  bottom: 20px;
  left: 20px;
  font-size: 15px;
  user-select: none;
  color: #67a23c;
}
</style>