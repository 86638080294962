<template>
  <div class="main_wrap">
    <div class="result_content">
      <el-result
        icon="error"
        :title="$t('notfound.title_notfound')"
        :subTitle="$t('notfound.subtitle_notfound')"
      >
        <!-- <template #extra>
          <el-button type="primary" size="medium">Return</el-button>
        </template> -->
      </el-result>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shown: {},
      pageData: {},
      formData: {},
    };
  },
  mounted() {
    document.title = this.$t("title.notfound");
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.main_wrap {
  width: 100%;
  height: 100%;
}
.result_content {
  margin-top: 200px;
}
</style>