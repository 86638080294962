<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <HelloWorld msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import {
  onMounted,
  getCurrentInstance
} from 'vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  mounted() {
    // const { ctx } = getCurrentInstance();
    // console.log(ctx.$axios)
  }
}
</script>
