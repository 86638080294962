<template>
  <div class="base" style="width: 100%; height: 100%">
    <transition name="el-fade-in-linear">
      <div class="lanesheet_container">
        <div class="head_container">
          <el-image :src="require('@/assets/images/ifi.png')"
            ><template #error>
              <div class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div> </template
          ></el-image>
          <div class="more_pic">
            <div class="el-icon-more"></div>
          </div>
          <el-image :src="require('@/assets/images/dingtalk1.jpg')"
            ><template #error>
              <div class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div> </template
          ></el-image>
        </div>
        <div class="card_container">
          <el-card shadow="always" style="display: inline-block">
            <div class="whole_wrap" v-show="show.wholeShow">
              <div class="top_container">
                <div class="tools_wrap">
                  <el-radio-group
                    v-model="_forms.approvalForm.type"
                    size="small"
                  >
                    <el-radio-button :label="1">{{
                      $t("lanesheet.transfer_application")
                    }}</el-radio-button>
                    <el-radio-button :label="2">{{
                      $t("lanesheet.job_transfer_application")
                    }}</el-radio-button>
                    <el-radio-button :label="3">{{
                      $t("lanesheet.resignation_handover_report")
                    }}</el-radio-button>
                  </el-radio-group>
                </div>
              </div>
              <div class="body_container">
                <div class="form_wrap">
                  <div class="ta" v-if="_forms.approvalForm.type === 1">
                    <el-form
                      :model="_forms.ta"
                      ref="ta_s"
                      class="ta_s"
                      size="mini"
                      label-position="right"
                      label-width="auto"
                    >
                      <el-form-item :label="$t('lanesheet.employee')">
                        <el-input
                          v-model="_forms.ta.transfer.employee_name"
                          maxlength="70"
                          show-word-limit
                          size="small"
                          :placeholder="$t('text.placeholder')"
                        ></el-input>
                      </el-form-item>

                      <!-- <el-form-item :label="$t('lanesheet.title')">
                      <el-input
                        v-model="_forms.ta.title"
                        maxlength="70"
                        show-word-limit
                        size="small"
                        :placeholder="$t('text.placeholder')"
                      ></el-input>
                    </el-form-item> -->

                      <el-form-item :label="$t('lanesheet.department')">
                        <!-- <el-input
                          v-model="_forms.ta.transfer.department"
                          maxlength="70"
                          show-word-limit
                          size="small"
                          :placeholder="$t('text.placeholder')"
                        ></el-input> -->

                        <el-select
                          v-model="_forms.ta.transfer.department"
                          filterable
                          size="small"
                          :placeholder="$t('text.placeholder_select')"
                        >
                          <el-option
                            v-for="(item, index) in _pages.departments"
                            :key="index"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>

                      <el-form-item :label="$t('lanesheet.dateOfEntry')">
                        <el-date-picker
                          v-model="_forms.ta.transfer.entry_date"
                          type="date"
                          size="small"
                          :placeholder="$t('text.placeholder_pick_day')"
                        ></el-date-picker>
                      </el-form-item>

                      <el-form-item :label="$t('lanesheet.transferDate')">
                        <el-date-picker
                          v-model="_forms.ta.transfer.transfer_date"
                          type="date"
                          size="small"
                          :placeholder="$t('text.placeholder_pick_day')"
                        ></el-date-picker>
                      </el-form-item>

                      <div class="s_bt_1">
                        <el-form-item>
                          <el-button
                            type="success"
                            size="small"
                            @click="reportForm(_forms.ta)"
                            :loading="_pages.reportLoad"
                            >{{ $t("lanesheet.btn_report") }}</el-button
                          >
                        </el-form-item>
                      </div>
                    </el-form>
                  </div>

                  <div class="jta" v-else-if="_forms.approvalForm.type === 2">
                    <el-form
                      :model="_forms.jta"
                      ref="jta_s"
                      class="jta_s"
                      size="mini"
                      label-position="right"
                      label-width="auto"
                    >
                      <el-form-item :label="$t('lanesheet.employee')">
                        <el-input
                          v-model="_forms.jta.jobTransfer.employee_name"
                          maxlength="70"
                          show-word-limit
                          size="small"
                          :placeholder="$t('text.placeholder')"
                        ></el-input>
                      </el-form-item>

                      <el-form-item :label="$t('lanesheet.newdept')">
                        <!-- <el-input
                          v-model="_forms.jta.jobTransfer.new_department"
                          maxlength="70"
                          show-word-limit
                          size="small"
                          :placeholder="$t('text.placeholder')"
                        ></el-input> -->

                        <el-select
                          v-model="_forms.jta.jobTransfer.new_department"
                          filterable
                          size="small"
                          :placeholder="$t('text.placeholder_select')"
                        >
                          <el-option
                            v-for="(item, index) in _pages.departments"
                            :key="index"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>

                      <el-form-item :label="$t('lanesheet.newtitle')">
                        <el-input
                          v-model="_forms.jta.jobTransfer.new_title"
                          maxlength="70"
                          show-word-limit
                          size="small"
                          :placeholder="$t('text.placeholder')"
                        ></el-input>
                      </el-form-item>

                      <el-form-item :label="$t('lanesheet.dateOfTransferred')">
                        <el-date-picker
                          v-model="_forms.jta.jobTransfer.transferred_date"
                          type="date"
                          size="small"
                          :placeholder="$t('text.placeholder_pick_day')"
                        ></el-date-picker>
                      </el-form-item>

                      <el-form-item :label="$t('lanesheet.approver1')">
                        <el-input
                          v-model="_forms.jta.jobTransfer.original_approver"
                          maxlength="70"
                          show-word-limit
                          size="small"
                          :placeholder="$t('text.placeholder')"
                        ></el-input>
                      </el-form-item>

                      <el-form-item :label="$t('lanesheet.approver2')">
                        <el-input
                          v-model="_forms.jta.jobTransfer.transferred_approver"
                          maxlength="70"
                          show-word-limit
                          size="small"
                          :placeholder="$t('text.placeholder')"
                        ></el-input>
                      </el-form-item>

                      <el-form-item :label="$t('lanesheet.reason')">
                        <el-input
                          type="textarea"
                          :rows="5"
                          v-model="_forms.jta.jobTransfer.reson_for_transfer"
                          maxlength="250"
                          show-word-limit
                          size="small"
                          :placeholder="$t('text.placeholder')"
                        ></el-input>
                      </el-form-item>

                      <div class="s_bt_1">
                        <el-form-item>
                          <el-button
                            type="success"
                            size="small"
                            @click="reportForm(_forms.jta)"
                            :loading="_pages.reportLoad"
                            >{{ $t("lanesheet.btn_report") }}</el-button
                          >
                        </el-form-item>
                      </div>
                    </el-form>
                  </div>
                  <div class="rhr" v-else-if="_forms.approvalForm.type === 3">
                    <el-form
                      :model="_forms.rhr"
                      ref="rhr_s"
                      class="rhr_s"
                      size="mini"
                      label-position="right"
                      label-width="auto"
                    >
                      <el-form-item :label="$t('lanesheet.employee')">
                        <el-input
                          v-model="_forms.rhr.resignationHandover.employee_name"
                          maxlength="70"
                          show-word-limit
                          size="small"
                          :placeholder="$t('text.placeholder')"
                        ></el-input>
                      </el-form-item>

                      <el-form-item :label="$t('lanesheet.superior')">
                        <el-input
                          v-model="_forms.rhr.resignationHandover.superior_name"
                          maxlength="70"
                          show-word-limit
                          size="small"
                          :placeholder="$t('text.placeholder')"
                        ></el-input>
                      </el-form-item>

                      <div class="s_bt_1">
                        <el-form-item>
                          <el-button
                            type="success"
                            size="small"
                            @click="reportForm(_forms.rhr)"
                            :loading="_pages.reportLoad"
                            >{{ $t("lanesheet.btn_report") }}</el-button
                          >
                        </el-form-item>
                      </div>
                    </el-form>
                  </div>
                  <div class="none" v-else>Nothing here!</div>
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: {
        wholeShow: false
      },
      _pages: {
        lang: '',
        reportLoad: false,
        departments: [
          {
            value: 'Sales',
            label: 'Sales'
          },
          {
            value: 'Logistics',
            label: 'Logistics'
          },
          {
            value: 'eCommerce',
            label: 'eCommerce'
          },
          {
            value: 'Technical Support',
            label: 'Technical Support'
          },
          {
            value: 'Marketing - Reviews',
            label: 'Marketing - Reviews'
          },
          {
            value: 'Marketing - Social Media',
            label: 'Marketing - Social Media'
          },
          {
            value: 'Marketing - Digital Marketing',
            label: 'Marketing - Digital Marketing'
          },
          {
            value: 'Business Support - IT',
            label: 'Business Support - IT'
          },
          {
            value: 'Business Support - Graphics',
            label: 'Business Support - Graphics'
          },
          {
            value: 'Business Support - Administration',
            label: 'Business Support - Administration'
          },
          {
            value: 'Business Support - Finance/Accounting',
            label: 'Business Support - Finance/Accounting'
          },
          {
            value: 'Business Support - Project',
            label: 'Business Support - Project'
          }
        ]
      },
      _forms: {
        approvalForm: {
          type: 1,
        },
        ta: {
          type: 1,
          transfer: {
            employee_name: '',
            // title: '',
            department: '',
            entry_date: '',
            transfer_date: ''
          }
        },
        jta: {
          type: 2,
          jobTransfer: {
            employee_name: '',
            new_department: '',
            new_title: '',
            transferred_date: '',
            original_approver: '',
            transferred_approver: '',
            reson_for_transfer: ''
          }
        },
        rhr: {
          type: 3,
          resignationHandover: {
            employee_name: '',
            superior_name: ''
          }

        }
      }
    };
  },
  mounted() {
    this.$i18n.locale = 'en';
    document.title = this.$t('title.lanesheet');
    sessionStorage.setItem('lang', this.$i18n.locale);
    this._pages.lang = sessionStorage.getItem('lang');
    setTimeout(() => { this.show.wholeShow = true }, 1);
  },

  methods: {
    checkAllPropertiesNotEmpty(obj) {
      // 遍历对象的所有属性
      for (let key in obj) {
        // 如果属性值是对象，则递归检查其属性
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          if (!this.checkAllPropertiesNotEmpty(obj[key])) {
            // 如果子对象有属性为空，则返回false
            return false;
          }
        } else if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
          // 如果属性值为空（null, undefined, 空字符串），则返回false
          return false;
        }
      }
      // 如果所有属性都检查完毕且没有为空的属性，则返回true
      return true;
    },
    reportForm(form) {
      if (form.type === 1) {
        if (this.checkAllPropertiesNotEmpty(form.transfer)) {

        } else {

          this.$notify.error({
            title: this.$t("notify.title_err_type1"),
            message: this.$t('notify.cotent_all_items_required')
          });

          return;
        }
      } else if (form.type === 2) {
        if (this.checkAllPropertiesNotEmpty(form.jobTransfer)) {

        } else {

          this.$notify.error({
            title: this.$t("notify.title_err_type1"),
            message: this.$t('notify.cotent_all_items_required')
          });

          return;
        }
      }
      else if (form.type === 3) {
        if (this.checkAllPropertiesNotEmpty(form.resignationHandover)) {

        } else {

          this.$notify.error({
            title: this.$t("notify.title_err_type1"),
            message: this.$t('notify.cotent_all_items_required')
          });

          return;
        }
      } else {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_ill')
        });

      }

      this._pages.reportLoad = true;
      this.$axios({
        method: 'post',
        url: this.$api + '/api/dingtalk/approval/report',
        data: form
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.$ElMessage.success({
              message: this.$t("notify.content_reported"),
              type: 'success'
            });

            this._pages.reportLoad = false;

            setTimeout(() => {
              location.reload();
            }, 300);

            return;
          } else if (response.data.code == '701') {

            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_ill')
            });

            this._pages.reportLoad = false;
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });

            this._pages.reportLoad = false;
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });

          this._pages.reportLoad = false;
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });

        this._pages.reportLoad = false;
        return;
      });
    }

  }
}
</script>

<style scoped lang="scss">
.base {
  overflow: hidden;
}
.lanesheet_container {
  margin-top: 10vh;
}
.head_container {
  margin-bottom: 5vh;
}
.more_pic {
  color: #409eff;
  display: inline-block;
}
.more_pic div {
  vertical-align: middle;
  width: 3vw;
  font-size: 30px;
  margin-top: -2vh;
}
.whole_wrap {
  width: 100%;
  height: 100%;
}
.head_container .el-image {
  width: 35px;
}
.top_container {
  margin-top: 5vh;
}
// .body_container {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 50vh;
// }
.form_wrap {
  width: 20vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3vh;
}
.form_wrap .jta {
  margin-left: -4.6vw;
}
.s_bt_1 {
  display: flex;
  justify-content: flex-end;
}
</style>