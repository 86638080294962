<template>
  <div class="base" style="width: 100%; height: 100%">
    <transition name="el-fade-in-linear">
      <div class="whole_wrap" v-show="show.wholeShow">
        <div class="head_wrap">
          <el-image :src="require('@/assets/images/cover.png')"
            ><template #error>
              <div class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div> </template
          ></el-image>
          <!-- <h2 v-if="_pages.results.length > 0">
            {{ $t("bigfiveresults.top_title") }}
          </h2> -->
        </div>
        <div class="body_wrap">
          <div class="tips_content">
            <!-- <el-alert
              style="margin-bottom: 35px"
              type="info"
              :description="$t('bigfiveresults.alert_notice_results')"
              :closable="false"
              show-icon
              effect="dark"
            ></el-alert> -->
            <p
              style="
                float: left;
                font-size: 13px;
                color: #67c23a;
                padding-bottom: 3px;
              "
            >
              {{
                $t("bigfive.toptext_1") +
                _pages.results.length +
                $t("text.record")
              }}
            </p>
          </div>
          <div class="results_content" v-loading="_pages.tableLoading">
            <div v-if="_pages.results.length > 0">
              <el-table
                :data="
                  _pages.results.slice(
                    (_pages.pagination.currentPage - 1) * 15,
                    _pages.pagination.currentPage * 15
                  )
                "
                stripe
                border
                :header-cell-style="{
                  textAlign: 'center',
                  backgroundColor: '#dcdfe6',
                }"
                :cell-style="{ textAlign: 'center' }"
                :default-sort="{ prop: 'created_time', order: 'descending' }"
              >
                <el-table-column :label="$t('bigfiveresults.survey_name')">
                  <template #default="scope">
                    {{ scope.row.name }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('bigfiveresults.survey_post')">
                  <template #default="scope">
                    {{ scope.row.post }}
                  </template>
                </el-table-column>

                <el-table-column
                  :label="$t('bigfiveresults.survey_time')"
                  prop="created_time"
                  sortable
                >
                  <template #default="scope">
                    {{ scope.row.created_time }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('bigfive.targetN')">
                  <template #default="scope">
                    {{ scope.row.score1 }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('bigfive.targetE')">
                  <template #default="scope">
                    {{ scope.row.score2 }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('bigfive.targetO')">
                  <template #default="scope">
                    {{ scope.row.score3 }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('bigfive.targetC')">
                  <template #default="scope">
                    {{ scope.row.score5 }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('bigfive.targetA')">
                  <template #default="scope">
                    {{ scope.row.score4 }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('question.label_options')">
                  <template #default="scope">
                    <el-button
                      @click="removeRecord(scope.row)"
                      type="danger"
                      circle
                      size="small"
                      class="el-icon-delete"
                    ></el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="_tb_pagination">
                <el-pagination
                  background
                  @current-change="paginationCurrentChange"
                  :page-size="15"
                  :current-page="_pages.pagination.currentPage"
                  layout="prev, pager, next"
                  :total="_pages.results.length"
                />
              </div>
              <!-- <el-collapse v-model="_pages.resultActiveNames">
                <div>
                  <el-collapse-item
                    v-for="(result, index) in _pages.results"
                    :key="index"
                    :index="index"
                    :name="index"
                    :title="$t('bigfiveresults.survey_name') + result.name"
                  >
                    <p style="color: #f56c6c">
                      {{ $t("bigfiveresults.survey_name") }}{{ result.name }}
                    </p>
                    <p style="color: #67a23c">
                      {{ $t("bigfiveresults.survey_post") }}{{ result.post }}
                    </p>
                    <p style="color: #e6a23c">
                      {{ $t("bigfiveresults.survey_time")
                      }}{{ result.created_time }}
                    </p>
                    <p style="color: rgba(255, 69, 0, 0.68)">
                      {{ $t("bigfive.targetN") + "：" }}{{ result.score1 }}
                    </p>
                    <p style="color: rgba(0, 186, 189, 1)">
                      {{ $t("bigfive.targetE") + "：" }}{{ result.score2 }}
                    </p>
                    <p style="color: rgba(30, 215, 95, 1)">
                      {{ $t("bigfive.targetO") + "：" }}{{ result.score3 }}
                    </p>
                    <p style="color: rgba(255, 69, 0, 0.68)">
                      {{ $t("bigfive.targetA") + "：" }}{{ result.score4 }}
                    </p>
                    <p style="color: rgba(84, 53, 147, 0.68)">
                      {{ $t("bigfive.targetC") + "：" }}{{ result.score5 }}
                    </p>
                  </el-collapse-item>
                </div>
              </el-collapse> -->
            </div>
            <div v-else>
              <el-empty
                :description="$t('bigfiveresults.empty_results_discription')"
                :image-size="300"
              >
                <el-button type="success" @click="reload" size="small">{{
                  $t("text.btn_refresh")
                }}</el-button>
              </el-empty>
            </div>
          </div>
        </div>
        <div class="foot_wrap"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as utilsjs from '@/assets/js/utils'
import * as mountederjs from '@/assets/js/mounteder'
export default {
  data() {
    return {
      show: {
        wholeShow: false
      },
      _pages: {
        username: '',
        userinfo: {},
        lang: '',
        results: [],
        resultActiveNames: [],
        tableLoading: true,
        pagination: {
          currentPage: 1
        }
      },
      _forms: {

      }
    };
  },
  mounted() {
    this.$i18n.locale = 'ch';
    document.title = this.$t('title.bigfive_results');
    sessionStorage.setItem('lang', this.$i18n.locale);
    this._pages.lang = sessionStorage.getItem('lang');

    this.queryResults();

    setTimeout(() => { this.show.wholeShow = true }, 1);

  },
  methods: {
    reload() {
      location.reload();
    },
    queryResults() {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/survey/queryBFInfo'
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this._pages.results = response.data.data;

            return;
          } else if (response.data.code == '210') {
            this.$notify.warning({
              title: this.$t("notify.title_notification"),
              message: this.$t('notify.result_null')
            });

            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
      this._pages.tableLoading = false;
    },
    removeRecord(record) {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/survey/removeBigFiveRecord',
        data: record
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.queryResults();

            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    paginationCurrentChange(currentPage) {
      //current page
      this._pages.pagination.currentPage = currentPage;
    },
  }
}
</script>

<style scoped lang="scss">
.head_wrap h1 {
  margin-top: 5vh;
}
.head_wrap .el-image {
  width: 12vh;
  float: left;
  padding-left: 2vw;
}
.body_wrap {
  padding-top: 7vh;
  width: 100%;
}
.tips_content {
  width: 80%;
  margin: 0 auto;
  // padding-top: 2vh;
}
.results_content {
  width: 80%;
  margin: 0 auto;
  // padding-bottom: 5vh;
}
.results_content p {
  text-align: left;
  font-size: 17px;
  line-height: 35px;
  font-family: "Microsoft YaHei", "微软雅黑";
}
._tb_pagination {
  position: fixed;
  bottom: 3vh;
  right: 10vw;
}
</style>