<template>
  <div class="base" style="width: 100%; height: 100%">
    <transition name="el-fade-in-linear">
      <div
        class="whole_wrap"
        v-show="show.wholeShow"
        v-loading.fullscreen.lock="pageData.fullLoading"
      >
        <div class="head_wrap">
          <div class="setting_content">
            <el-button
              type="danger"
              icon="el-icon-s-tools"
              @click="openSettingPopover"
              circle
              disabled
            ></el-button>
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="$t('chips.tip_apichip_update')"
              placement="bottom"
            >
              <el-button
                type="success"
                icon="el-icon-download"
                @click="updateChipsData"
                circle
              ></el-button>
            </el-tooltip>
          </div>
          <div class="notice_content">
            <span
              >{{ $t("chips.span_updated")
              }}{{ pageData.chipLog.updated }}</span
            >
            <br />
            <span>octopart.com/findchips.com</span>
          </div>
          <div class="setting_table_content">
            <el-dialog
              v-model="pageData.settingTableVisible"
              title="Shipping address"
            >
              <el-table :data="gridData">
                <el-table-column property="date" label="Date" width="150" />
                <el-table-column property="name" label="Name" width="200" />
                <el-table-column property="address" label="Address" />
              </el-table>
            </el-dialog>
          </div>
        </div>
        <div class="body_wrap">
          <div class="result_content">
            <el-collapse
              v-model="pageData.chipActiveNames"
              @change="handleChange"
            >
              <el-collapse-item
                :title="
                  $t('chips.mpn') +
                  '：' +
                  chipgroup[0].mpn +
                  ' ' +
                  $t('chips.k3id') +
                  '：' +
                  chipgroup[0].fid +
                  ' ' +
                  $t('chips.count_stock') +
                  '：' +
                  chipgroup[0].count
                "
                :name="index"
                v-for="(chipgroup, index) in pageData.chipList"
                :key="index"
                :index="index"
              >
                <!-- <div
                  class="chipsInfoBody"
                  v-for="(chip, index) in chipgroup"
                  :key="index"
                  :index="index"
                > -->
                <el-table
                  :data="chipgroup"
                  :header-cell-style="{
                    textAlign: 'center',
                    backgroundColor: '#f2f6fc',
                  }"
                  :cell-style="{ textAlign: 'center' }"
                  stripe
                  border
                >
                  <el-table-column :label="$t('chips.mpn')">
                    <template #default="scope">
                      {{ scope.row.mpn }}
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('chips.k3id')">
                    <template #default="scope">
                      {{ scope.row.fid }}
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('chips.country')">
                    <template #default="scope">
                      {{ scope.row.country }}
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('chips.distributor')">
                    <template #default="scope">
                      {{ scope.row.distributor }}
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('chips.moq')">
                    <template #default="scope">
                      {{ scope.row.moq }}
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('chips.sku')">
                    <template #default="scope">
                      {{ scope.row.sku }}
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('chips.stock')">
                    <template #default="scope">
                      {{ scope.row.stock }}
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('chips.updated')">
                    <template #default="scope">
                      {{ scope.row.updated }}
                    </template>
                  </el-table-column>
                </el-table>
                <!-- </div> -->
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
        <div class="foot_wrap"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as utilsjs from '@/assets/js/utils'
import * as mountederjs from '@/assets/js/mounteder'
export default {
  data() {
    return {
      show: {
        wholeShow: false
      },
      pageData: {
        // username: '',
        // userinfo: {},
        lang: '',
        settingTableVisible: false,
        chipList: [],
        chipLog: {},
        chipActiveNames: [],
        chipTemplateList: [],
        chipTemplateRequestList: [],
        newChipDataList: [],
        fullLoading: false
      },
      formData: {

      }
    };
  },
  mounted() {
    document.title = this.$t('title.chips');
    // this.pageData.username = sessionStorage.getItem('username');
    // this.pageData.userinfo = JSON.parse(sessionStorage.getItem(this.pageData.username));
    this.$i18n.locale = 'ch';
    sessionStorage.setItem('lang', this.$i18n.locale);
    this.pageData.lang = sessionStorage.getItem('lang');
    setTimeout(() => { this.show.wholeShow = true }, 1);
    this.initLocalChipData();
    this.getChipTemplate();
  },
  methods: {
    openSettingPopover() {
      this.pageData.settingTableVisible = true;
    },
    handleChange() { },
    initLocalChipData() {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/chip/queryChipData'
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.pageData.chipList = response.data.data.map;
            this.pageData.chipLog = response.data.data.log;

            for (let _index in this.pageData.chipList) {
              for (let index in this.pageData.chipList[_index]) {
                if (this.pageData.chipList[_index][0].count == undefined) {
                  this.pageData.chipList[_index][0].count = 0;
                }
                this.pageData.chipList[_index][0].count = this.pageData.chipList[_index][0].count + this.pageData.chipList[_index][index].stock;
              }
            }
            // console.log(this.pageData.chipList)

            return;
          } else if (response.data.code == '210') {
            this.$notify.warning({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.result_null')
            });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    getChipTemplate() {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/chip/queryChipTemplate'
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {

            this.pageData.chipTemplateList = response.data.data;

            for (let item of this.pageData.chipTemplateList) {
              this.pageData.chipTemplateRequestList.push(item.mpn);
            }

            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    updateChipsData() {
      this.$confirm(this.$t("chips.text_updatefromapi_body"), this.$t("text.text_confirm_title"), {
        confirmButtonText: this.$t("common.btn_message_confirm"),
        cancelButtonText: this.$t("common.btn_message_cancel"),
        type: 'warning'
      }).then(() => {
        this.pageData.newChipDataList = [];
        this.pageData.fullLoading = true;
        let requester = {};
        requester.dataList = JSON.parse(JSON.stringify(this.pageData.chipTemplateRequestList));

        if (this.pageData.chipTemplateRequestList.length > 0) {
          this.$axios({
            method: 'post',
            url: this.$api + '/api/chip/updateChipsDataFromApi',
            data: requester
          }).then((response) => {
            if (response.status == 200) {
              if (response.data.code == '200') {
                for (let chipList of response.data.data) {
                  if (chipList.data.search_mpn.results != null) {
                    for (let result of chipList.data.search_mpn.results) {
                      for (let seller of result.part.sellers) {
                        for (let offer of seller.offers) {
                          let chipDataObj = {};
                          if (utilsjs.strIsEmpty(result.part.mpn)) {
                            chipDataObj.mpn = '-';
                          } else {
                            chipDataObj.mpn = result.part.mpn;
                          }
                          if (utilsjs.strIsEmpty(seller.company.name)) {
                            chipDataObj.distributor = '-';
                          } else {
                            chipDataObj.distributor = seller.company.name;
                          }
                          if (utilsjs.strIsEmpty(seller.country)) {
                            chipDataObj.country = '-';
                          } else {
                            chipDataObj.country = seller.country;
                          }
                          if (utilsjs.strIsEmpty(offer.sku)) {
                            chipDataObj.sku = '-';
                          } else {
                            chipDataObj.sku = offer.sku;
                          }
                          if (utilsjs.strIsEmpty(offer.inventory_level)) {
                            chipDataObj.stock = 0;
                          } else {
                            chipDataObj.stock = offer.inventory_level;
                          }
                          if (utilsjs.strIsEmpty(offer.moq)) {
                            chipDataObj.moq = 0;
                          } else {
                            chipDataObj.moq = offer.moq;
                          }
                          if (utilsjs.strIsEmpty(offer.updated)) {
                            chipDataObj.updated = '-';
                          } else {
                            chipDataObj.updated = offer.updated;
                          }

                          this.pageData.newChipDataList.push(chipDataObj);
                        }
                      }
                    }
                  }
                }

                this.rebuildChipData(this.pageData.newChipDataList);

                return;
              } else {
                //code = 700 and more
                this.pageData.fullLoading = false;
                this.$notify.error({
                  title: this.$t("notify.title_server"),
                  message: this.$t('notify.content_refused')
                });
                return;
              }
            } else {
              this.pageData.fullLoading = false;
              this.$notify.error({
                title: this.$t('notify.title_server'),
                message: this.$t('notify.content_server') + response.status
              });
              return;
            }
          }).catch((error) => {
            this.pageData.fullLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          });
        } else {
          this.pageData.fullLoading = false;
          this.$notify.error({
            title: this.$t("notify.title_server"),
            message: this.$t('chips.notify_chiptemplate_null')
          });
          return;
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t("text.text_canceled")
        });
      });
    },
    rebuildChipData(apiDataList) {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/chip/remakeChipData',
        data: apiDataList
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.initLocalChipData();

            this.pageData.fullLoading = false;

            return;
          } else {
            this.pageData.fullLoading = false;
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else if (response.data.code == '290') {
          this.pageData.fullLoading = false;
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('chips.notify_chipdata_partly')
          });
          return;
        } else {
          this.pageData.fullLoading = false;
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.pageData.fullLoading = false;
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
  }
}
</script>

<style scoped lang="scss">
.setting_content {
  position: fixed;
  right: 50px;
  top: 20px;
}
.notice_content {
  margin-top: 40px;
}
.notice_content span {
  color: #909399;
}
.result_content {
  width: 1300px;
  padding-top: 100px;
  margin: 0 auto;
}
</style>