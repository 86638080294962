<template>
  <div
    class="base"
    style="width: 100%; height: 100%"
    v-loading="pageData.loadResultLoading"
  >
    <transition name="el-fade-in-linear">
      <div class="whole_wrap" v-show="show.wholeShow">
        <div class="head_wrap">
          <!-- <div class="tools_content">
            <el-affix :offset="20" position="top">
              <el-button type="primary" v-print="'#results_print'">
                {{ $t("text.btn_print") }}
              </el-button>
            </el-affix>
          </div> -->
        </div>
        <div class="body_wrap" v-if="pageData.dataFinished" id="results_print">
          <div class="cover_content">
            <div class="coverimage_content">
              <el-image
                v-if="pageData.lang == 'ch'"
                :src="require('@/assets/images/cover.png')"
                ><template #error>
                  <div class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div> </template
              ></el-image>
              <el-image v-else :src="require('@/assets/images/cover_en.jpg')"
                ><template #error>
                  <div class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div> </template
              ></el-image>
            </div>
            <div class="labels_content">
              <span>{{ $t("result.main_title") }}</span>
              <div class="labels_items">
                <el-row
                  ><h4>{{ $t("result.staffname") }}</h4>
                  <a v-if="pageData.lang == 'ch'">{{
                    pageData.participantInfo.fullname
                  }}</a>
                  <a v-else>{{ pageData.participantInfo.englishname }}</a>
                </el-row>
                <el-row
                  ><h4>{{ $t("result.dept_name") }}</h4>
                  <a v-if="pageData.lang == 'ch'">{{
                    pageData.participantInfo.dept_name
                  }}</a>
                  <a v-else>{{
                    pageData.participantInfo.dept_name_en
                  }}</a></el-row
                >
                <el-row
                  ><h4>{{ $t("result.total_score") }}</h4>
                  <a>{{ pageData.totalWeightScore }}</a></el-row
                >
                <el-row
                  ><h4>{{ $t("result.report_time") }}</h4>
                  <a>{{ pageData.activityInfo.created_time }}</a></el-row
                >
              </div>
            </div>
          </div>
          <!-- <el-divider style="margin-top: 100px; margin-bottom: 10px"
            ><span style="color: #606266">{{
              $t("text.one")
            }}</span></el-divider
          > -->
          <div class="preface_content">
            <div class="sub_title_content">
              <span>{{ $t("result.tilte_preface") }}</span>
            </div>
            <div class="preface_texts_content">
              <el-alert
                effect="dark"
                type="warning"
                :description="$t('result.preface_span1')"
                show-icon
                :closable="false"
              >
              </el-alert>
              <h4 class="preface_h4">
                {{ $t("result.preface_h4_1") }}
              </h4>
              <ul class="preface_ul">
                <li>{{ $t("result.preface_ul_1_li_1") }}</li>
                <li>{{ $t("result.preface_ul_1_li_2") }}</li>
                <li>{{ $t("result.preface_ul_1_li_3") }}</li>
                <li>{{ $t("result.preface_ul_1_li_4") }}</li>
              </ul>

              <el-alert
                effect="dark"
                type="warning"
                :description="$t('result.preface_span2')"
                show-icon
                :closable="false"
              >
              </el-alert>
              <el-alert
                effect="dark"
                type="warning"
                :description="$t('result.preface_span3')"
                show-icon
                :closable="false"
              >
              </el-alert>
              <h4 class="preface_h4">{{ $t("result.preface_h4_2") }}</h4>
              <ul class="preface_ul">
                <li>{{ $t("result.preface_ul_2_li_1") }}</li>
                <li>{{ $t("result.preface_ul_2_li_2") }}</li>
                <li>{{ $t("result.preface_ul_2_li_3") }}</li>
                <li>{{ $t("result.preface_ul_2_li_4") }}</li>
                <li>{{ $t("result.preface_ul_2_li_5") }}</li>
                <li>{{ $t("result.preface_ul_2_li_6") }}</li>
                <li>{{ $t("result.preface_ul_2_li_7") }}</li>
              </ul>
            </div>
          </div>
          <!-- <el-divider style="margin-top: 30px; margin-bottom: 10px"
            ><span style="color: #606266">{{
              $t("text.two")
            }}</span></el-divider
          > -->
          <div class="discription_content">
            <div class="sub_title_content">
              <span>{{ $t("result.title_standard") }}</span>
            </div>
            <div class="score_level_content">
              <el-table
                stripe
                border
                :data="pageData.scoreLevelList"
                :header-cell-style="{
                  textAlign: 'center',
                  backgroundColor: '#2790fc',
                  color: '#303133',
                }"
                :cell-style="{ textAlign: 'center', color: '#303133' }"
              >
                <el-table-column :label="$t('result.lebel_score5')">
                  <template #default="scope">
                    {{ scope.row.score5.toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('result.lebel_score4')">
                  <template #default="scope">
                    {{ scope.row.score4.toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('result.lebel_score3')">
                  <template #default="scope">
                    {{ scope.row.score3.toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('result.lebel_score2')">
                  <template #default="scope">
                    {{ scope.row.score2.toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('result.lebel_score1')">
                  <template #default="scope">
                    {{ scope.row.score1.toFixed(2) }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="text_explain_content">
              <el-row>{{ $t("result.explain_1") }}</el-row>
              <el-row>{{ $t("result.explain_2") }}</el-row>
              <el-row>{{ $t("result.explain_3") }}</el-row>
              <el-row>{{ $t("result.explain_4") }}</el-row>
              <el-row>{{ $t("result.explain_5") }}</el-row>
              <el-row>{{ $t("result.explain_6") }}</el-row>
            </div>
          </div>
          <div class="datasource_content">
            <div class="sub_title_content">
              <span>{{ $t("result.title_info_source") }}</span>
            </div>
            <div class="weight_content">
              <el-table
                v-if="pageData.targetResultsList[0].is_manager === 1"
                stripe
                border
                :data="pageData.weightList1"
                :header-cell-style="{
                  textAlign: 'center',
                  backgroundColor: '#2790fc',
                  color: '#303133',
                }"
                :cell-style="{ textAlign: 'center', color: '#303133' }"
              >
                <el-table-column :label="$t('result.title_info_source')">
                  <template #default="scope">
                    {{ scope.row.source }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('result.label_weight')">
                  <template #default="scope">
                    {{ scope.row.weightPercent }}
                  </template>
                </el-table-column>
              </el-table>

              <el-table
                v-else-if="pageData.targetResultsList[0].is_manager === 0"
                stripe
                border
                :data="pageData.weightList2"
                :header-cell-style="{
                  textAlign: 'center',
                  backgroundColor: '#2790fc',
                  color: '#303133',
                }"
                :cell-style="{ textAlign: 'center', color: '#303133' }"
              >
                <el-table-column :label="$t('result.title_info_source')">
                  <template #default="scope">
                    {{ scope.row.source }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('result.label_weight')">
                  <template #default="scope">
                    {{ scope.row.weightPercent }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <!-- <el-divider style="margin-top: 180px"
            ><span style="color: #606266">{{
              $t("text.three")
            }}</span></el-divider
          > -->
          <!-- TODO:Targets -->
          <div class="target_results_content">
            <div class="sub_title_content">
              <span>{{ $t("result.title_target_s") }}</span>
            </div>
            <div class="target_radar_content">
              <canvas
                id="target_radar_chart"
                width="500px"
                height="500px"
              ></canvas>
            </div>
            <div class="target_table_content">
              <el-table
                stripe
                border
                :data="pageData.targetResultsList"
                :header-cell-style="{
                  textAlign: 'center',
                  backgroundColor: '#2790fc',
                  color: '#303133',
                }"
                :cell-style="{ textAlign: 'center', color: '#303133' }"
              >
                <el-table-column
                  :label="$t('result.label_target_name')"
                  width="200"
                >
                  <template #default="scope">
                    <span v-if="pageData.lang == 'ch'">{{
                      scope.row.target_name
                    }}</span>
                    <span v-else> {{ scope.row.target_name_en }}</span>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('result.label_superior')">
                  <template #default="scope">
                    <span>{{ scope.row.scoreAvgType2.toFixed(2) }}</span>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('result.label_equal')">
                  <template #default="scope">
                    <span>{{ scope.row.scoreAvgType1.toFixed(2) }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="$t('result.label_subordinate')"
                  v-if="pageData.targetResultsList[0].is_manager === 1"
                >
                  <template #default="scope">
                    <span>{{ scope.row.scoreAvgType0.toFixed(2) }}</span>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('result.label_self')">
                  <template #default="scope">
                    <span>{{ scope.row.scoreAvgType3.toFixed(2) }}</span>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('result.label_score')">
                  <template #default="scope">
                    <span>{{ scope.row.scoreWeight.toFixed(2) }}</span>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('result.label_company_avg')">
                  <template #default="scope">
                    <span>{{ scope.row.scoreCompany.toFixed(2) }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- <el-divider style="margin-top: 60px"
              ><span style="color: #606266">{{
                $t("text.four")
              }}</span></el-divider
            > -->
            <div class="target_solve_content">
              <div class="target_solve_discription">
                <el-row>{{ $t("result.target_solve_discription") }}</el-row>
                <div class="target_solve_example_slider">
                  <!-- <el-slider
                    v-model="pageData.sliderExampleVal"
                    :max="100"
                    :min="0"
                    :step="1"
                    :disabled="true"
                  ></el-slider>
                  <span style="color: #000; font-size: 15px">{{
                    $t("result.target_solve_discription_2")
                  }}</span> -->
                  <div v-if="pageData.lang == 'ch'">
                    <el-image
                      :src="require('@/assets/images/slider_exp_ch.png')"
                      ><template #error>
                        <div class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div> </template
                    ></el-image>
                  </div>
                  <div v-else>
                    <el-image
                      :src="require('@/assets/images/slider_exp_en.png')"
                      ><template #error>
                        <div class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div> </template
                    ></el-image>
                  </div>
                </div>
              </div>
              <div
                v-for="(target, index) in pageData.targetSolveList"
                :key="index"
                :index="index"
                class="target_solve_items"
              >
                <div v-if="pageData.lang == 'ch'" class="target_span_title">
                  {{ $t("result.title_target") }}{{ target.target_name }}
                </div>
                <div v-else class="target_span_title">
                  {{ $t("result.title_target") }}{{ target.target_name_en }}
                </div>
                <div class="solve_table_content">
                  <!-- TODO:target table -->
                  <el-table
                    stripe
                    border
                    :data="target.scoreList"
                    :header-cell-style="{
                      textAlign: 'center',
                      backgroundColor: '#2790fc',
                      color: '#303133',
                    }"
                    :cell-style="{ textAlign: 'center', color: '#303133' }"
                    style="margin-top: 15px"
                    v-if="target.is_manager === 0"
                  >
                    <el-table-column :label="$t('result.target_type')">
                      <template #default="scope">
                        <span>{{ scope.row.type }}</span>
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('result.label_superior')">
                      <template #default="scope">
                        <span v-if="scope.row.isGeneral">{{
                          scope.row.superior
                        }}</span>
                        <span
                          v-else-if="
                            scope.row.superiorMax == scope.row.superiorMin
                          "
                          >{{ $t("text.identical") }}</span
                        >
                        <span v-else>
                          <el-slider
                            v-model="scope.row.superior"
                            :max="scope.row.superiorMax"
                            :min="scope.row.superiorMin"
                            :step="0.01"
                            :disabled="true"
                          ></el-slider>
                        </span>
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('result.label_equal')">
                      <template #default="scope">
                        <span v-if="scope.row.isGeneral">{{
                          scope.row.equal
                        }}</span>
                        <span
                          v-else-if="scope.row.equalMax == scope.row.equalMin"
                          >{{ $t("text.identical") }}</span
                        >
                        <span v-else>
                          <el-slider
                            v-model="scope.row.equal"
                            :max="scope.row.equalMax"
                            :min="scope.row.equalMin"
                            :step="0.01"
                            :disabled="true"
                          ></el-slider>
                        </span>
                      </template>
                    </el-table-column>

                    <el-table-column
                      :label="$t('result.label_subordinate')"
                      v-if="target.is_manager === 1"
                    >
                      <template #default="scope">
                        <span v-if="scope.row.isGeneral">{{
                          scope.row.subordinate
                        }}</span>
                        <span
                          v-else-if="
                            scope.row.subordinateMax == scope.row.subordinateMin
                          "
                          >{{ $t("text.identical") }}</span
                        >
                        <span v-else>
                          <el-slider
                            v-model="scope.row.subordinate"
                            :max="scope.row.subordinateMax"
                            :min="scope.row.subordinateMin"
                            :step="0.01"
                            :disabled="true"
                          ></el-slider>
                        </span>
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('result.label_self')">
                      <template #default="scope">
                        <span v-if="scope.row.isGeneral">{{
                          scope.row.self
                        }}</span>
                        <span
                          v-else-if="scope.row.selfMax == scope.row.selfMin"
                          >{{ $t("text.identical") }}</span
                        >
                        <span v-else>
                          <el-slider
                            v-model="scope.row.self"
                            :max="scope.row.selfMax"
                            :min="scope.row.selfMin"
                            :step="0.01"
                            :disabled="true"
                          ></el-slider>
                        </span>
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('result.label_all')">
                      <template #default="scope">
                        <span v-if="scope.row.isGeneral">{{
                          scope.row.weight
                        }}</span>
                        <span
                          v-else-if="scope.row.weightMax == scope.row.weightMin"
                          >{{ $t("text.identical") }}</span
                        >
                        <span v-else>
                          <el-slider
                            v-model="scope.row.weight"
                            :max="scope.row.weightMax"
                            :min="scope.row.weightMin"
                            :step="0.01"
                            :disabled="true"
                          ></el-slider>
                        </span>
                      </template>
                    </el-table-column>
                  </el-table>

                  <el-table
                    stripe
                    border
                    :data="target.scoreList"
                    :header-cell-style="{
                      textAlign: 'center',
                      backgroundColor: '#2790fc',
                      color: '#303133',
                    }"
                    :cell-style="{ textAlign: 'center', color: '#303133' }"
                    style="margin-top: 15px"
                    v-else-if="target.is_manager === 1"
                  >
                    <el-table-column :label="$t('result.target_type')">
                      <template #default="scope">
                        <span>{{ scope.row.type }}</span>
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('result.label_superior')">
                      <template #default="scope">
                        <span v-if="scope.row.isGeneral">{{
                          scope.row.superior
                        }}</span>
                        <span
                          v-else-if="
                            scope.row.superiorMax == scope.row.superiorMin
                          "
                          >{{ $t("text.identical") }}</span
                        >
                        <span v-else>
                          <el-slider
                            v-model="scope.row.superior"
                            :max="scope.row.superiorMax"
                            :min="scope.row.superiorMin"
                            :step="0.01"
                            :disabled="true"
                          ></el-slider>
                        </span>
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('result.label_equal')">
                      <template #default="scope">
                        <span v-if="scope.row.isGeneral">{{
                          scope.row.equal
                        }}</span>
                        <span
                          v-else-if="scope.row.equalMax == scope.row.equalMin"
                          >{{ $t("text.identical") }}</span
                        >
                        <span v-else>
                          <el-slider
                            v-model="scope.row.equal"
                            :max="scope.row.equalMax"
                            :min="scope.row.equalMin"
                            :step="0.01"
                            :disabled="true"
                          ></el-slider>
                        </span>
                      </template>
                    </el-table-column>

                    <el-table-column
                      :label="$t('result.label_subordinate')"
                      v-if="target.is_manager === 1"
                    >
                      <template #default="scope">
                        <span v-if="scope.row.isGeneral">{{
                          scope.row.subordinate
                        }}</span>
                        <span
                          v-else-if="
                            scope.row.subordinateMax == scope.row.subordinateMin
                          "
                          >{{ $t("text.identical") }}</span
                        >
                        <span v-else>
                          <el-slider
                            v-model="scope.row.subordinate"
                            :max="scope.row.subordinateMax"
                            :min="scope.row.subordinateMin"
                            :step="0.01"
                            :disabled="true"
                          ></el-slider>
                        </span>
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('result.label_self')">
                      <template #default="scope">
                        <span v-if="scope.row.isGeneral">{{
                          scope.row.self
                        }}</span>
                        <span
                          v-else-if="scope.row.selfMax == scope.row.selfMin"
                          >{{ $t("text.identical") }}</span
                        >
                        <span v-else>
                          <el-slider
                            v-model="scope.row.self"
                            :max="scope.row.selfMax"
                            :min="scope.row.selfMin"
                            :step="0.01"
                            :disabled="true"
                          ></el-slider>
                        </span>
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('result.label_all')">
                      <template #default="scope">
                        <span v-if="scope.row.isGeneral">{{
                          scope.row.weight
                        }}</span>
                        <span
                          v-else-if="scope.row.weightMax == scope.row.weightMin"
                          >{{ $t("text.identical") }}</span
                        >
                        <span v-else>
                          <el-slider
                            v-model="scope.row.weight"
                            :max="scope.row.weightMax"
                            :min="scope.row.weightMin"
                            :step="0.01"
                            :disabled="true"
                          ></el-slider>
                        </span>
                      </template>
                    </el-table-column>
                  </el-table>

                  <div
                    class="question_analysis_content"
                    v-for="question in target.questionAnalysisList"
                    :key="question"
                  >
                    <div
                      v-if="pageData.lang == 'ch'"
                      class="question_analysis_content_title"
                    >
                      {{ question.content }}
                    </div>
                    <div v-else class="question_analysis_content_title">
                      {{ question.content_en }}
                    </div>
                    <!-- TODO:question table -->
                    <el-table
                      stripe
                      border
                      :data="question.questionList"
                      :header-cell-style="{
                        textAlign: 'center',
                        backgroundColor: '#f6550f',
                        color: '#303133',
                      }"
                      :cell-style="{ textAlign: 'center', color: '#303133' }"
                      v-if="target.is_manager === 0"
                    >
                      <el-table-column :label="$t('result.target_type')">
                        <template #default="scope">
                          <span>{{ scope.row.type }}</span>
                        </template>
                      </el-table-column>

                      <el-table-column :label="$t('result.label_superior')">
                        <template #default="scope">
                          <span v-if="scope.row.isGeneral">{{
                            scope.row.superior
                          }}</span>
                          <span
                            v-else-if="
                              scope.row.superiorMax == scope.row.superiorMin
                            "
                            >{{ $t("text.identical") }}</span
                          >
                          <span v-else>
                            <el-slider
                              v-model="scope.row.superior"
                              :max="scope.row.superiorMax"
                              :min="scope.row.superiorMin"
                              :step="0.01"
                              :disabled="true"
                            ></el-slider>
                          </span>
                        </template>
                      </el-table-column>

                      <el-table-column :label="$t('result.label_equal')">
                        <template #default="scope">
                          <span v-if="scope.row.isGeneral">{{
                            scope.row.equal
                          }}</span>
                          <span
                            v-else-if="scope.row.equalMax == scope.row.equalMin"
                            >{{ $t("text.identical") }}</span
                          >
                          <span v-else>
                            <el-slider
                              v-model="scope.row.equal"
                              :max="scope.row.equalMax"
                              :min="scope.row.equalMin"
                              :step="0.01"
                              :disabled="true"
                            ></el-slider>
                          </span>
                        </template>
                      </el-table-column>

                      <el-table-column
                        :label="$t('result.label_subordinate')"
                        v-if="target.is_manager === 1"
                      >
                        <template #default="scope">
                          <span v-if="scope.row.isGeneral">{{
                            scope.row.subordinate
                          }}</span>
                          <span
                            v-else-if="
                              scope.row.subordinateMax ==
                              scope.row.subordinateMin
                            "
                            >{{ $t("text.identical") }}</span
                          >
                          <span v-else>
                            <el-slider
                              v-model="scope.row.subordinate"
                              :max="scope.row.subordinateMax"
                              :min="scope.row.subordinateMin"
                              :step="0.01"
                              :disabled="true"
                            ></el-slider>
                          </span>
                        </template>
                      </el-table-column>

                      <el-table-column :label="$t('result.label_self')">
                        <template #default="scope">
                          <span v-if="scope.row.isGeneral">{{
                            scope.row.self
                          }}</span>
                          <span
                            v-else-if="scope.row.selfMax == scope.row.selfMin"
                            >{{ $t("text.identical") }}</span
                          >
                          <span v-else>
                            <el-slider
                              v-model="scope.row.self"
                              :max="scope.row.selfMax"
                              :min="scope.row.selfMin"
                              :step="0.01"
                              :disabled="true"
                            ></el-slider>
                          </span>
                        </template>
                      </el-table-column>

                      <el-table-column :label="$t('result.label_all')">
                        <template #default="scope">
                          <span v-if="scope.row.isGeneral">{{
                            scope.row.weight
                          }}</span>
                          <span
                            v-else-if="
                              scope.row.weightMax == scope.row.weightMin
                            "
                            >{{ $t("text.identical") }}</span
                          >
                          <span v-else>
                            <el-slider
                              v-model="scope.row.weight"
                              :max="scope.row.weightMax"
                              :min="scope.row.weightMin"
                              :step="0.01"
                              :disabled="true"
                            ></el-slider>
                          </span>
                        </template>
                      </el-table-column>
                    </el-table>

                    <el-table
                      stripe
                      border
                      :data="question.questionList"
                      :header-cell-style="{
                        textAlign: 'center',
                        backgroundColor: '#f6550f',
                        color: '#303133',
                      }"
                      :cell-style="{ textAlign: 'center', color: '#303133' }"
                      v-else-if="target.is_manager === 1"
                    >
                      <el-table-column :label="$t('result.target_type')">
                        <template #default="scope">
                          <span>{{ scope.row.type }}</span>
                        </template>
                      </el-table-column>

                      <el-table-column :label="$t('result.label_superior')">
                        <template #default="scope">
                          <span v-if="scope.row.isGeneral">{{
                            scope.row.superior
                          }}</span>
                          <span
                            v-else-if="
                              scope.row.superiorMax == scope.row.superiorMin
                            "
                            >{{ $t("text.identical") }}</span
                          >
                          <span v-else>
                            <el-slider
                              v-model="scope.row.superior"
                              :max="scope.row.superiorMax"
                              :min="scope.row.superiorMin"
                              :step="0.01"
                              :disabled="true"
                            ></el-slider>
                          </span>
                        </template>
                      </el-table-column>

                      <el-table-column :label="$t('result.label_equal')">
                        <template #default="scope">
                          <span v-if="scope.row.isGeneral">{{
                            scope.row.equal
                          }}</span>
                          <span
                            v-else-if="scope.row.equalMax == scope.row.equalMin"
                            >{{ $t("text.identical") }}</span
                          >
                          <span v-else>
                            <el-slider
                              v-model="scope.row.equal"
                              :max="scope.row.equalMax"
                              :min="scope.row.equalMin"
                              :step="0.01"
                              :disabled="true"
                            ></el-slider>
                          </span>
                        </template>
                      </el-table-column>

                      <el-table-column
                        :label="$t('result.label_subordinate')"
                        v-if="target.is_manager === 1"
                      >
                        <template #default="scope">
                          <span v-if="scope.row.isGeneral">{{
                            scope.row.subordinate
                          }}</span>
                          <span
                            v-else-if="
                              scope.row.subordinateMax ==
                              scope.row.subordinateMin
                            "
                            >{{ $t("text.identical") }}</span
                          >
                          <span v-else>
                            <el-slider
                              v-model="scope.row.subordinate"
                              :max="scope.row.subordinateMax"
                              :min="scope.row.subordinateMin"
                              :step="0.01"
                              :disabled="true"
                            ></el-slider>
                          </span>
                        </template>
                      </el-table-column>

                      <el-table-column :label="$t('result.label_self')">
                        <template #default="scope">
                          <span v-if="scope.row.isGeneral">{{
                            scope.row.self
                          }}</span>
                          <span
                            v-else-if="scope.row.selfMax == scope.row.selfMin"
                            >{{ $t("text.identical") }}</span
                          >
                          <span v-else>
                            <el-slider
                              v-model="scope.row.self"
                              :max="scope.row.selfMax"
                              :min="scope.row.selfMin"
                              :step="0.01"
                              :disabled="true"
                            ></el-slider>
                          </span>
                        </template>
                      </el-table-column>

                      <el-table-column :label="$t('result.label_all')">
                        <template #default="scope">
                          <span v-if="scope.row.isGeneral">{{
                            scope.row.weight
                          }}</span>
                          <span
                            v-else-if="
                              scope.row.weightMax == scope.row.weightMin
                            "
                            >{{ $t("text.identical") }}</span
                          >
                          <span v-else>
                            <el-slider
                              v-model="scope.row.weight"
                              :max="scope.row.weightMax"
                              :min="scope.row.weightMin"
                              :step="0.01"
                              :disabled="true"
                            ></el-slider>
                          </span>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- TODO:Difffrent -->
          <div class="cognition_content">
            <div class="sub_title_content">
              <span>{{ $t("result.title_cognition") }}</span>
            </div>
            <div class="cognition_discription_content">
              <el-row>{{ $t("result.cognition_discription") }}</el-row>
              <ul v-show="pageData.lang == 'ch'">
                <li>{{ $t("result.cognition_li_1") }}</li>
                <li>{{ $t("result.cognition_li_2") }}</li>
                <li>{{ $t("result.cognition_li_3") }}</li>
                <li>{{ $t("result.cognition_li_4") }}</li>
              </ul>
            </div>
            <div class="cognition_card_content">
              <el-card>
                <template #header>
                  <div class="cognition_card_header">
                    <span>{{ $t("result.cognition_card_title_1") }}</span>
                  </div>
                </template>
                <div v-if="pageData.cognitionList1.length > 0">
                  <div
                    v-for="target in pageData.cognitionList1"
                    :key="target"
                    class="cognition_card_body_li_1"
                  >
                    {{ target }}
                  </div>
                </div>
                <div v-else class="cognition_card_null">
                  {{ $t("text.not_available") }}
                </div>
              </el-card>
              <el-card>
                <template #header>
                  <div class="cognition_card_header">
                    <span>{{ $t("result.cognition_card_title_2") }}</span>
                  </div>
                </template>
                <div v-if="pageData.cognitionList2.length > 0">
                  <div
                    v-for="target in pageData.cognitionList2"
                    :key="target"
                    class="cognition_card_body_li_2"
                  >
                    {{ target }}
                  </div>
                </div>
                <div v-else class="cognition_card_null">
                  {{ $t("text.not_available") }}
                </div>
              </el-card>
              <el-card>
                <template #header>
                  <div class="cognition_card_header">
                    <span>{{ $t("result.cognition_card_title_3") }}</span>
                  </div>
                </template>
                <div v-if="pageData.cognitionList3.length > 0">
                  <div
                    v-for="target in pageData.cognitionList3"
                    :key="target"
                    class="cognition_card_body_li_3"
                  >
                    {{ target }}
                  </div>
                </div>
                <div v-else class="cognition_card_null">
                  {{ $t("text.not_available") }}
                </div>
              </el-card>
              <el-card>
                <template #header>
                  <div class="cognition_card_header">
                    <span>{{ $t("result.cognition_card_title_4") }}</span>
                  </div>
                </template>
                <div v-if="pageData.cognitionList4.length > 0">
                  <div
                    v-for="target in pageData.cognitionList4"
                    :key="target"
                    class="cognition_card_body_li_4"
                  >
                    {{ target }}
                  </div>
                </div>
                <div v-else class="cognition_card_null">
                  {{ $t("text.not_available") }}
                </div>
              </el-card>
            </div>
          </div>
          <!-- TODO:behaviour content -->
          <div class="behaviours_content">
            <div class="sub_title_content">
              <span>{{ $t("result.title_behaviours") }}</span>
            </div>
            <div class="behaviour_exp_content">
              <div class="self_exp_item"></div>
              {{ $t("result.chart_label_self") }}
              <div class="others_exp_item"></div>
              {{ $t("result.chart_label_weight") }}
            </div>
            <div class="advantage_discription">
              <el-row v-if="pageData.targetResultsList[0].is_manager === 1">{{
                $t("result.advantage_discription_2")
              }}</el-row>
              <el-row
                v-else-if="pageData.targetResultsList[0].is_manager === 0"
                >{{ $t("result.advantage_discription_1") }}</el-row
              >
            </div>
            <div class="advantage_table_content">
              <el-table
                :data="
                  pageData.questionAllListBeforeFormat.slice(
                    0,
                    pageData.advantageSize
                  )
                "
                border
                :header-cell-style="{
                  textAlign: 'center',
                  backgroundColor: '#2790fc',
                  color: '#303133',
                }"
                :cell-style="{ textAlign: 'center', color: '#303133' }"
              >
                <el-table-column :label="$t('result.label_behaviour_item')">
                  <template #default="scope">
                    <span v-if="pageData.lang == 'ch'">{{
                      scope.row.content + "(" + scope.row.target_name + ")"
                    }}</span>
                    <span v-else>{{
                      scope.row.content_en +
                      "(" +
                      scope.row.target_name_en +
                      ")"
                    }}</span>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('result.label_result_compare')">
                  <template #default="scope">
                    <canvas
                      :id="'advantage_result_compare_' + scope.$index"
                      width="50px"
                      height="50px"
                    >
                    </canvas>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('result.label_difference')">
                  <template #default="scope">
                    <span
                      v-if="
                        scope.row.scoreQsWeight - scope.row.scoreQsAvgType3 >= 0
                      "
                      style="color: #000"
                      >{{
                        (
                          scope.row.scoreQsWeight - scope.row.scoreQsAvgType3
                        ).toFixed(2)
                      }}</span
                    >
                    <span v-else style="color: red">{{
                      (
                        scope.row.scoreQsWeight - scope.row.scoreQsAvgType3
                      ).toFixed(2)
                    }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="improvable_discription">
              <el-row v-if="pageData.targetResultsList[0].is_manager === 1">{{
                $t("result.improvable_discription_2")
              }}</el-row>
              <el-row
                v-else-if="pageData.targetResultsList[0].is_manager === 0"
                >{{ $t("result.improvable_discription_1") }}</el-row
              >
            </div>
            <div class="improvable_table_content">
              <el-table
                :data="
                  pageData.questionAllListBeforeFormat.slice(
                    pageData.questionAllListBeforeFormat.length -
                      pageData.advantageSize -
                      1,
                    pageData.questionAllListBeforeFormat.length - 1
                  )
                "
                border
                :header-cell-style="{
                  textAlign: 'center',
                  backgroundColor: '#2790fc',
                  color: '#303133',
                }"
                :cell-style="{ textAlign: 'center', color: '#303133' }"
              >
                <el-table-column :label="$t('result.label_behaviour_item')">
                  <template #default="scope">
                    <span v-if="pageData.lang == 'ch'">{{
                      scope.row.content + "(" + scope.row.target_name + ")"
                    }}</span>
                    <span v-else>{{
                      scope.row.content_en +
                      "(" +
                      scope.row.target_name_en +
                      ")"
                    }}</span>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('result.label_result_compare')">
                  <template #default="scope">
                    <canvas
                      :id="'improvable_result_compare_' + scope.$index"
                      width="50px"
                      height="50px"
                    >
                    </canvas>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('result.label_difference')">
                  <template #default="scope">
                    <span
                      v-if="
                        scope.row.scoreQsWeight - scope.row.scoreQsAvgType3 >= 0
                      "
                      style="color: #000"
                      >{{
                        (
                          scope.row.scoreQsWeight - scope.row.scoreQsAvgType3
                        ).toFixed(2)
                      }}</span
                    >
                    <span v-else style="color: red">{{
                      (
                        scope.row.scoreQsWeight - scope.row.scoreQsAvgType3
                      ).toFixed(2)
                    }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <!-- TODO:self rating -->
          <div class="self_rating_content">
            <div class="sub_title_content">
              <span>{{ $t("result.title_self_rating") }}</span>
            </div>
            <div class="self_rating_body">
              <div class="self_rating_discription">
                <el-row>{{ $t("result.self_rating_discription") }}</el-row>
              </div>
            </div>
            <div class="self_rating_table_content">
              <el-table
                :data="pageData.questionAllSelfRatingBeforeList.slice(0, 10)"
                border
                :header-cell-style="{
                  textAlign: 'center',
                  backgroundColor: '#2790fc',
                  color: '#303133',
                }"
                :cell-style="{ textAlign: 'center', color: '#303133' }"
              >
                <el-table-column
                  width="900"
                  :label="$t('result.label_question')"
                >
                  <template #default="scope">
                    <span v-if="pageData.lang == 'ch'">{{
                      scope.row.content
                    }}</span>
                    <span v-else>{{ scope.row.content_en }}</span>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('result.chart_label_weight')">
                  <template #default="scope">
                    <span>{{ scope.row.scoreQsWeight.toFixed(2) }}</span>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('result.chart_label_self')">
                  <template #default="scope">
                    <span>{{ scope.row.scoreQsAvgType3 }}</span>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('result.label_difference')">
                  <template #default="scope">
                    <span
                      v-if="
                        scope.row.scoreQsWeight - scope.row.scoreQsAvgType3 >= 0
                      "
                      style="color: #000"
                      >{{
                        (
                          scope.row.scoreQsWeight - scope.row.scoreQsAvgType3
                        ).toFixed(2)
                      }}</span
                    >
                    <span v-else style="color: red">{{
                      (
                        scope.row.scoreQsWeight - scope.row.scoreQsAvgType3
                      ).toFixed(2)
                    }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <!-- TODO:subjective -->
          <div class="subjective_content">
            <div class="sub_title_content">
              <span>{{ $t("result.title_subjective_feedback") }}</span>
            </div>
            <div class="subjective_body">
              <div class="subjective_discription">
                <el-row>{{ $t("result.subjective_discription") }}</el-row>
              </div>
              <ul
                v-for="(subjective, index) in pageData.subjectiveList"
                :key="index"
                :index="index"
              >
                <div class="question_body_content" v-if="pageData.lang == 'ch'">
                  {{ subjective.question_content }}
                </div>
                <div v-else>{{ subjective.question_content_en }}</div>
                <div v-if="subjective.type0List.length != 0">
                  <div style="font-size: 16px; font-weight: normal; color: red">
                    {{ $t("result.label_subordinate") }}:
                  </div>
                  <li
                    v-for="(content, index) in subjective.type0List"
                    :key="index"
                    :index="index"
                  >
                    <div>{{ index + 1 + "." + content }}</div>
                  </li>
                </div>
                <div>
                  <div style="font-size: 16px; font-weight: normal; color: red">
                    {{ $t("result.label_equal") }}:
                  </div>
                  <li
                    v-for="(content, index) in subjective.type1List"
                    :key="index"
                    :index="index"
                  >
                    <div>{{ index + 1 + "." + content }}</div>
                  </li>
                </div>
                <div>
                  <div style="font-size: 16px; font-weight: normal; color: red">
                    {{ $t("result.label_superior") }}:
                  </div>
                  <li
                    v-for="(content, index) in subjective.type2List"
                    :key="index"
                    :index="index"
                  >
                    <div>{{ index + 1 + "." + content }}</div>
                  </li>
                </div>
                <div>
                  <div style="font-size: 16px; font-weight: normal; color: red">
                    {{ $t("result.chart_label_self") }}:
                  </div>
                  <li
                    v-for="(content, index) in subjective.type3List"
                    :key="index"
                    :index="index"
                  >
                    <div>{{ index + 1 + "." + content }}</div>
                  </li>
                </div>
              </ul>
            </div>
          </div>
          <!-- TODO:Scores -->
          <div class="score_content">
            <div class="sub_title_content">
              <span>{{ $t("result.title_score") }}</span>
            </div>
            <div class="score_table_content">
              <el-table
                :data="pageData.questionAllListBeforeFormat"
                border
                :header-cell-style="{
                  textAlign: 'center',
                  backgroundColor: '#2790fc',
                  color: '#303133',
                }"
                :cell-style="{ textAlign: 'center', color: '#303133' }"
                show-summary
                :summary-method="getSummaries"
                v-if="pageData.is_manager === 1"
              >
                <!-- v-if="pageData.questionAllListBeforeFormat[0].is_manager === 1" -->
                <el-table-column
                  :label="$t('result.label_question')"
                  width="800"
                >
                  <template #default="scope">
                    <span v-if="pageData.lang == 'ch'">{{
                      scope.row.content
                    }}</span>
                    <span v-else>{{ scope.row.content_en }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="$t('result.label_superior')"
                  prop="scoreQsAvgType2"
                >
                  <template #default="scope">
                    <span>{{ scope.row.scoreQsAvgType2.toFixed(2) }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="$t('result.label_equal')"
                  prop="scoreQsAvgType1"
                >
                  <template #default="scope">
                    <span>{{ scope.row.scoreQsAvgType1.toFixed(2) }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="$t('result.label_subordinate')"
                  prop="scoreQsAvgType0"
                  v-if="pageData.is_manager === 1"
                >
                  <!-- pageData.questionAllListBeforeFormat[0].is_manager === 1 -->
                  <template #default="scope">
                    <span>{{ scope.row.scoreQsAvgType0.toFixed(2) }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="$t('result.label_self')"
                  prop="scoreQsAvgType3"
                >
                  <template #default="scope">
                    <span>{{ scope.row.scoreQsAvgType3.toFixed(2) }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="$t('result.label_all')"
                  prop="scoreQsWeight"
                >
                  <template #default="scope">
                    <span>{{ scope.row.scoreQsWeight.toFixed(2) }}</span>
                  </template>
                </el-table-column>
              </el-table>

              <el-table
                :data="pageData.questionAllListBeforeFormat"
                border
                :header-cell-style="{
                  textAlign: 'center',
                  backgroundColor: '#2790fc',
                  color: '#303133',
                }"
                :cell-style="{ textAlign: 'center', color: '#303133' }"
                show-summary
                :summary-method="getSummaries"
                v-else-if="pageData.is_manager === 0"
              >
                <!-- pageData.questionAllListBeforeFormat[0].is_manager === 0 -->
                <el-table-column
                  :label="$t('result.label_question')"
                  width="800"
                >
                  <template #default="scope">
                    <span v-if="pageData.lang == 'ch'">{{
                      scope.row.content
                    }}</span>
                    <span v-else>{{ scope.row.content_en }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="$t('result.label_superior')"
                  prop="scoreQsAvgType2"
                >
                  <template #default="scope">
                    <span>{{ scope.row.scoreQsAvgType2.toFixed(2) }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="$t('result.label_equal')"
                  prop="scoreQsAvgType1"
                >
                  <template #default="scope">
                    <span>{{ scope.row.scoreQsAvgType1.toFixed(2) }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="$t('result.label_subordinate')"
                  prop="scoreQsAvgType0"
                  v-if="pageData.is_manager === 1"
                >
                  <!-- pageData.questionAllListBeforeFormat[0].is_manager === 1 -->
                  <template #default="scope">
                    <span>{{ scope.row.scoreQsAvgType0.toFixed(2) }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="$t('result.label_self')"
                  prop="scoreQsAvgType3"
                >
                  <template #default="scope">
                    <span>{{ scope.row.scoreQsAvgType3.toFixed(2) }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="$t('result.label_all')"
                  prop="scoreQsWeight"
                >
                  <template #default="scope">
                    <span>{{ scope.row.scoreQsWeight.toFixed(2) }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div v-else>
          <el-empty
            :description="$t('result.notify_questionnaire_isnot_finished')"
            :image-size="300"
          >
            <el-button type="primary" @click="reload">{{
              $t("text.btn_refresh")
            }}</el-button>
          </el-empty>
        </div>
        <div class="foot_wrap"></div>
      </div>
    </transition>
    <div class="fullpage_backup"><el-backtop></el-backtop></div>
  </div>
</template>

<script>
import Chart from 'chart.js'
import * as echarts from 'echarts';
import * as utilsjs from '@/assets/js/utils'
import * as mountederjs from '@/assets/js/mounteder'
export default {
  data() {
    return {
      show: {
        wholeShow: false
      },
      pageData: {
        username: '',
        userinfo: {},
        lang: '',
        loadResultLoading: true,
        dataFinished: false,
        scoreLevelList: [{ score5: 5.00, score4: 4.00, score3: 3.00, score2: 2.00, score1: 1.00 }],
        weightList1: [{}],
        weightList2: [{}],
        targetList: [],
        targetResultsList: [],
        targetSolveList: [],
        participantInfo: {},
        activityInfo: {},
        targetLabelsList: [],
        targetSelfScoreList: [],
        targetScoreList: [],
        targetCompanyAvgList: [],
        sliderExampleVal: 30,
        cognitionList1: [],
        cognitionList2: [],
        cognitionList3: [],
        cognitionList4: [],
        cognitionListOteher: [],
        questionAllListBeforeFormat: [],
        questionAllListAfterFormat: [],
        questionAllSelfRatingBeforeList: [],
        subjectiveList: [],
        advantageSize: 0,
        totalWeightScore: 0.00,
        is_manager: null
      },
      formData: {

      }
    };
  },
  mounted() {
    this.pageData.username = sessionStorage.getItem('username');
    this.pageData.userinfo = JSON.parse(sessionStorage.getItem(this.pageData.username));
    this.pageData.lang = sessionStorage.getItem('lang');

    //mounted
    this.$i18n.locale = sessionStorage.getItem("lang");
    if (utilsjs.strIsEmpty(this.pageData.username)) {
      this.$router.push({ name: 'Login' });
    }

    // if (mountederjs.evaluateM(this)) {
    if (utilsjs.strIsEmpty(this.$route.query.questionnaire_id) ||
      utilsjs.strIsEmpty(this.$route.query.activity_id) ||
      utilsjs.strIsEmpty(this.$route.query.participant)) {
      window.close();
    } else {
      this.initWeight();
      this.getTargetList();
      setTimeout(() => { this.show.wholeShow = true }, 1);
    }
    // } else {
    //   this.$router.push({ name: 'Login' });
    // }
  },
  methods: {
    initWeight() {
      this.pageData.weightList1 = [
        { source: this.$t('result.label_superior'), weightPercent: '40%' },
        { source: this.$t('result.label_equal'), weightPercent: '40%' },
        { source: this.$t('result.label_subordinate'), weightPercent: '20%' },
        { source: this.$t('result.label_self'), weightPercent: '0%' },
        { source: this.$t('result.label_total'), weightPercent: '100%' }
      ];
      this.pageData.weightList2 = [
        { source: this.$t('result.label_superior'), weightPercent: '50%' },
        { source: this.$t('result.label_equal'), weightPercent: '50%' },
        { source: this.$t('result.label_self'), weightPercent: '0%' },
        { source: this.$t('result.label_total'), weightPercent: '100%' }
      ];
    },
    reload() {
      location.reload();
    },
    getTargetList() {
      let questionnaire = {};
      questionnaire.id = this.$route.query.questionnaire_id;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/anay/queryTargetListInQuestionnaire',
        data: questionnaire
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.pageData.targetList = response.data.data;

            let requestList = [];
            let requester = {};
            requester.username = this.pageData.username;
            requester.activity_id = this.$route.query.activity_id;
            requester.participant = this.$route.query.participant;
            requester.questionnaire_id = this.$route.query.questionnaire_id;

            for (let target of this.pageData.targetList) {
              let item = {};
              item.username = this.pageData.username;
              item.activity_id = this.$route.query.activity_id;
              item.target_id = target.target_id;
              item.participant = this.$route.query.participant;
              item.questionnaire_id = this.$route.query.questionnaire_id;
              requestList.push(item);

              if (this.pageData.lang == 'ch') {
                this.pageData.targetLabelsList.push(target.target_name);
              } else {
                this.pageData.targetLabelsList.push(target.target_name_en);
              }
            }

            this.getTargetResults(requestList);
            this.getSubjective(requester);

            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    getSubjective(requester) {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/anay/querySubjectiveResultsByActivity',
        data: requester
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            let questionList = [];
            for (let item of response.data.data) {
              questionList.push(item.question_id);
            }
            questionList = JSON.parse(JSON.stringify(utilsjs.distinctArr(questionList)));

            let resultList = [];
            for (let question_id of questionList) {
              let obj = {};
              obj.question_id = question_id;
              obj.type0List = [];
              obj.type1List = [];
              obj.type2List = [];
              obj.type3List = [];
              resultList.push(obj);
            }

            for (let item of response.data.data) {
              for (let subjective of resultList) {
                if (subjective.question_id == item.question_id) {
                  subjective.activity_id = item.activity_id;
                  subjective.activity_name = item.activity_name;
                  subjective.activity_name_en = item.activity_name_en;
                  subjective.question_content = item.question_content;
                  subjective.question_content_en = item.question_content_en;
                  if (item.level === 0) {
                    subjective.type0List.push(item.explain);
                  } else if (item.level === 1) {
                    subjective.type1List.push(item.explain);
                  }
                  else if (item.level === 2) {
                    subjective.type2List.push(item.explain);
                  }
                  else if (item.level === 3) {
                    subjective.type3List.push(item.explain);
                  }
                } else {
                  continue;
                }
              }
            }
            this.pageData.subjectiveList = JSON.parse(JSON.stringify(resultList));
            // console.log('===')
            // console.log(this.pageData.subjectiveList)
            // console.log('===')

            return;
          } else if (response.data.code == '210') {
            this.$notify.warning({
              title: this.$t("notify.title_err_type2"),
              message: this.$t('result.notify_questionnaire_isnot_finished'),
              duration: 0
            });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('result.label_total');
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += '';
        } else {
          sums[index] = 'N/A';
        }
      });

      //toFixed2
      for (let index in sums) {
        if (index == 0) {
          continue;
        } else {
          sums[index] = Number(sums[index]).toFixed(2);
        }
      }

      return sums;
    },
    getTargetResults(requestList) {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/anay/querySelectorResultsByActivity',
        data: requestList
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.pageData.dataFinished = true;
            this.pageData.loadResultLoading = false;
            this.pageData.targetResultsList = response.data.data.targetAnalyseList;
            this.pageData.participantInfo = response.data.data.internaluser;
            this.pageData.activityInfo = response.data.data.activity;

            let targetSolveList = [];

            if (this.pageData.targetResultsList[0].is_manager === 1) {
              this.pageData.advantageSize = 7;
              this.pageData.is_manager = 1;
            } else if (this.pageData.targetResultsList[0].is_manager === 0) {
              this.pageData.advantageSize = 5;
              this.pageData.is_manager = 0;
            }

            for (let result of this.pageData.targetResultsList) {
              this.pageData.targetSelfScoreList.push(result.scoreAvgType3.toFixed(2));
              this.pageData.targetScoreList.push(result.scoreWeight.toFixed(2));
              this.pageData.targetCompanyAvgList.push(result.scoreCompany.toFixed(2));

              //push items
              // let targetList = [];
              let targetListObj = {};
              let scoreList = [];
              let questionAnalysisList = [];
              let participantObject = {};
              let companyObject = {};
              let positionObject = {};

              targetListObj.target_id = result.target_id;
              targetListObj.target_name = result.target_name;
              targetListObj.target_name_en = result.target_name_en;
              targetListObj.is_manager = result.is_manager;
              if (result.is_manager === 1 && result.scoreAvgType0 != null) {
                participantObject.subordinate = Number(result.scoreAvgType0.toFixed(2));
                companyObject.subordinate = Number(result.scoreCompanyType0.toFixed(2));

                positionObject.subordinateMin = Number(result.scoreCompanyType0Min.toFixed(2));
                positionObject.subordinateMax = Number(result.scoreCompanyType0Max.toFixed(2));
                positionObject.subordinate = Number(result.scoreCompanyType0.toFixed(2));
              }

              participantObject.isGeneral = true;
              participantObject.type = this.$t('result.chart_label_score');
              participantObject.equal = Number(result.scoreAvgType1.toFixed(2));
              participantObject.superior = Number(result.scoreAvgType2.toFixed(2));
              participantObject.self = Number(result.scoreAvgType3.toFixed(2));
              participantObject.weight = Number(result.scoreWeight.toFixed(2));

              companyObject.isGeneral = true;
              companyObject.type = this.$t('result.label_company_avg');
              companyObject.equal = Number(result.scoreCompanyType1.toFixed(2));
              companyObject.superior = Number(result.scoreCompanyType2.toFixed(2));
              companyObject.self = Number(result.scoreCompanyType3.toFixed(2));
              companyObject.weight = Number(result.scoreCompany.toFixed(2));

              positionObject.isGeneral = false;
              positionObject.type = this.$t('result.table_text_position');
              positionObject.equal = Number(result.scoreAvgType1.toFixed(2));
              positionObject.equalMin = Number(result.scoreCompanyType1Min.toFixed(2));
              positionObject.equalMax = Number(result.scoreCompanyType1Max.toFixed(2));
              positionObject.superior = Number(result.scoreAvgType2.toFixed(2));
              positionObject.superiorMin = Number(result.scoreCompanyType2Min.toFixed(2));
              positionObject.superiorMax = Number(result.scoreCompanyType2Max.toFixed(2));
              positionObject.self = Number(result.scoreAvgType3.toFixed(2));
              positionObject.selfMin = Number(result.scoreCompanyType3Min.toFixed(2));
              positionObject.selfMax = Number(result.scoreCompanyType3Max.toFixed(2));
              positionObject.weight = Number(result.scoreCompany.toFixed(2));
              positionObject.weightMin = Number(result.scoreCompanyMin.toFixed(2));
              positionObject.weightMax = Number(result.scoreCompanyMax.toFixed(2));

              scoreList.push(participantObject);
              scoreList.push(companyObject);
              scoreList.push(positionObject);

              targetListObj.scoreList = scoreList;

              for (let questionAnalyse of result.questionAnalyseList) {
                questionAnalyse.target_name = result.target_name;
                questionAnalyse.target_name_en = result.target_name_en;

                let questionListObj = {};
                let questionList = [];
                let participantQuestionObject = {};
                let companyQuestionObject = {};
                let positionQuestionObject = {};

                questionListObj.target_id = result.target_id;
                questionListObj.target_name = result.target_name;
                questionListObj.target_name_en = result.target_name_en;
                questionListObj.question_id = questionAnalyse.question_id;
                questionListObj.content = questionAnalyse.content;
                questionListObj.content_en = questionAnalyse.content_en;
                questionListObj.is_manager = questionAnalyse.is_manager;

                if (result.is_manager === 1 && questionAnalyse.scoreQsAvgType0 != null) {
                  participantQuestionObject.subordinate = Number(questionAnalyse.scoreQsAvgType0.toFixed(2));
                  companyQuestionObject.subordinate = Number(questionAnalyse.scoreQsCompanyType0.toFixed(2));

                  positionQuestionObject.subordinateMin = Number(questionAnalyse.scoreQsCompanyType0Min.toFixed(2));
                  positionQuestionObject.subordinateMax = Number(questionAnalyse.scoreQsCompanyType0Max.toFixed(2));
                  positionQuestionObject.subordinate = Number(questionAnalyse.scoreQsCompanyType0.toFixed(2));
                }

                participantQuestionObject.isGeneral = true;
                participantQuestionObject.type = this.$t('result.chart_label_score');
                participantQuestionObject.equal = Number(questionAnalyse.scoreQsAvgType1.toFixed(2));

                participantQuestionObject.superior = Number(questionAnalyse.scoreQsAvgType2.toFixed(2));
                participantQuestionObject.self = Number(questionAnalyse.scoreQsAvgType3.toFixed(2));
                participantQuestionObject.weight = Number(questionAnalyse.scoreQsWeight.toFixed(2));

                companyQuestionObject.isGeneral = true;
                companyQuestionObject.type = this.$t('result.label_company_avg');
                companyQuestionObject.equal = Number(questionAnalyse.scoreQsCompanyType1.toFixed(2));
                companyQuestionObject.superior = Number(questionAnalyse.scoreQsCompanyType2.toFixed(2));
                companyQuestionObject.self = Number(questionAnalyse.scoreQsCompanyType3.toFixed(2));
                companyQuestionObject.weight = Number(questionAnalyse.scoreQsCompany.toFixed(2));

                positionQuestionObject.isGeneral = false;
                positionQuestionObject.type = this.$t('result.table_text_position');
                positionQuestionObject.equal = Number(questionAnalyse.scoreQsAvgType1.toFixed(2));
                positionQuestionObject.equalMin = Number(questionAnalyse.scoreQsCompanyType1Min.toFixed(2));
                positionQuestionObject.equalMax = Number(questionAnalyse.scoreQsCompanyType1Max.toFixed(2));
                positionQuestionObject.superior = Number(questionAnalyse.scoreQsAvgType2.toFixed(2));
                positionQuestionObject.superiorMin = Number(questionAnalyse.scoreQsCompanyType2Min.toFixed(2));
                positionQuestionObject.superiorMax = Number(questionAnalyse.scoreQsCompanyType2Max.toFixed(2));
                positionQuestionObject.self = Number(questionAnalyse.scoreQsAvgType3.toFixed(2));
                positionQuestionObject.selfMin = Number(questionAnalyse.scoreQsCompanyType3Min.toFixed(2));
                positionQuestionObject.selfMax = Number(questionAnalyse.scoreQsCompanyType3Max.toFixed(2));
                positionQuestionObject.weight = Number(questionAnalyse.scoreQsCompany.toFixed(2));
                positionQuestionObject.weightMin = Number(questionAnalyse.scoreQsCompanyMin.toFixed(2));
                positionQuestionObject.weightMax = Number(questionAnalyse.scoreQsCompanyMax.toFixed(2));

                questionList.push(participantQuestionObject);
                questionList.push(companyQuestionObject);
                questionList.push(positionQuestionObject);

                questionListObj.questionList = questionList;

                questionAnalysisList.push(questionListObj);

                //set actions before
                this.pageData.questionAllListBeforeFormat.push(questionAnalyse);
              }

              //set self_rating
              for (let questionsBefore of this.pageData.questionAllListBeforeFormat) {
                questionsBefore.cognitiveBiases = (questionsBefore.scoreQsWeight - questionsBefore.scoreQsAvgType3).toFixed(2);
              }

              targetListObj.questionAnalysisList = JSON.parse(JSON.stringify(questionAnalysisList));

              targetSolveList.push(targetListObj);

              //set cognition
              //type1
              if (result.scoreAvgType3 > result.scoreCompanyType3 && result.scoreWeight > result.scoreCompany) {
                if (this.pageData.lang == 'ch') {
                  this.pageData.cognitionList1.push(result.target_name);
                } else {
                  this.pageData.cognitionList1.push(result.target_name_en);
                }
              } else if (result.scoreAvgType3 < result.scoreCompanyType3 && result.scoreWeight > result.scoreCompany) {
                if (this.pageData.lang == 'ch') {
                  this.pageData.cognitionList2.push(result.target_name);
                } else {
                  this.pageData.cognitionList2.push(result.target_name_en);
                }
              } else if (result.scoreAvgType3 < result.scoreCompanyType3 && result.scoreWeight < result.scoreCompany) {
                if (this.pageData.lang == 'ch') {
                  this.pageData.cognitionList3.push(result.target_name);
                } else {
                  this.pageData.cognitionList3.push(result.target_name_en);
                }
              } else if (result.scoreAvgType3 > result.scoreCompanyType3 && result.scoreWeight < result.scoreCompany) {
                if (this.pageData.lang == 'ch') {
                  this.pageData.cognitionList4.push(result.target_name);
                } else {
                  this.pageData.cognitionList4.push(result.target_name_en);
                }
              } else {
                if (this.pageData.lang == 'ch') {
                  this.pageData.cognitionListOteher.push(result.target_name);
                } else {
                  this.pageData.cognitionListOteher.push(result.target_name_en);
                }
              }
            }

            this.pageData.targetSolveList = JSON.parse(JSON.stringify(targetSolveList));

            //set actions after
            for (let targetItem of this.pageData.targetSolveList) {
              for (let questionItem of targetItem.questionAnalysisList) {
                this.pageData.questionAllListAfterFormat.push(questionItem);
              }
            }

            // console.log(this.pageData.targetSelfScoreList)
            // console.log(this.pageData.targetScoreList)
            // console.log(this.pageData.targetCompanyAvgList)
            // console.log(this.pageData.targetSolveList)
            // console.log(this.pageData.questionAllListBeforeFormat)
            // console.log(this.pageData.questionAllListAfterFormat)

            this.pageData.questionAllSelfRatingBeforeList = JSON.parse(JSON.stringify(this.pageData.questionAllListBeforeFormat));
            this.pageData.questionAllListBeforeFormat.sort(utilsjs.sortByDesc('scoreQsWeight'));
            this.pageData.questionAllSelfRatingBeforeList.sort(utilsjs.sortByAsc('cognitiveBiases'));
            // console.log('---')
            // console.log(this.pageData.questionAllListBeforeFormat)
            // console.log(this.pageData.questionAllSelfRatingBeforeList)

            //set totalWeightScore
            if (this.pageData.questionAllListBeforeFormat.length > 0) {
              let totalScore = 0.00;
              for (let question of this.pageData.questionAllListBeforeFormat) {
                totalScore += question.scoreQsWeight;
              }
              this.pageData.totalWeightScore = (totalScore / this.pageData.questionAllListBeforeFormat.length).toFixed(2);
            }

            //init advantage bar
            for (let index in this.pageData.questionAllListBeforeFormat.slice(0, this.pageData.advantageSize)) {
              let barList = [];
              let barObjSelf = {};
              let barObjWeight = {};

              barObjSelf.label = this.$t('result.chart_label_self');
              barObjSelf.data = [this.pageData.questionAllListBeforeFormat[index].scoreQsAvgType3, 0];
              barObjSelf.backgroundColor = '#f6550f';

              barObjWeight.label = this.$t('result.chart_label_weight');
              barObjWeight.data = [this.pageData.questionAllListBeforeFormat[index].scoreQsWeight, 0];
              barObjWeight.backgroundColor = '#0b7ff7';

              barList.push(barObjSelf);
              barList.push(barObjWeight);

              this.$nextTick(function () {
                var ctx_bar = document.getElementById("advantage_result_compare_" + index);
                // console.log(ctx_bar)
                var ctx_bar_init = new Chart(ctx_bar, {
                  type: "horizontalBar",
                  data: {
                    // labels: [this.$t('result.label_result_compare')],
                    datasets: barList
                    // [
                    //   {
                    //     label: '自评',
                    //     data: [12, 0],
                    //     backgroundColor: '#f6550f',
                    //   },
                    //   {
                    //     label: '他评',
                    //     data: [15, 0],
                    //     backgroundColor: '#0b7ff7',
                    //   }
                    // ]
                  },
                  options: {
                    legend: {
                      display: false
                    },
                    dataset: {
                      barPercentage: 0.7
                    },
                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                            offsetGridLines: true
                          }
                        }
                      ],
                      yAxes: [
                        {
                          // barPercentage: 0.7,
                          gridLines: {
                            offsetGridLines: true
                          }
                        }
                      ]
                    }
                  }
                });
              })
            }

            //init improvable bar
            for (let index in this.pageData.questionAllListBeforeFormat.slice(
              this.pageData.questionAllListBeforeFormat.length - this.pageData.advantageSize - 1,
              this.pageData.questionAllListBeforeFormat.length - 1)) {
              let barList = [];
              let barObjSelf = {};
              let barObjWeight = {};

              barObjSelf.label = this.$t('result.chart_label_self');
              barObjSelf.data = [this.pageData.questionAllListBeforeFormat[index].scoreQsAvgType3, 0];
              barObjSelf.backgroundColor = '#f6550f';

              barObjWeight.label = this.$t('result.chart_label_weight');
              barObjWeight.data = [this.pageData.questionAllListBeforeFormat[index].scoreQsWeight, 0];
              barObjWeight.backgroundColor = '#0b7ff7';

              barList.push(barObjSelf);
              barList.push(barObjWeight);

              this.$nextTick(function () {
                var ctx_bar2 = document.getElementById("improvable_result_compare_" + index);
                // console.log(ctx_bar2)
                var ctx_bar_init = new Chart(ctx_bar2, {
                  type: "horizontalBar",
                  data: {
                    // labels: [this.$t('result.label_result_compare')],
                    datasets: barList
                  },
                  options: {
                    legend: {
                      display: false
                    },
                    dataset: {
                      barPercentage: 0.7
                    },
                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                            offsetGridLines: true
                          },
                          ticks: {
                            // stepSize:5,
                            // callback: function (value, index, values) {
                            //   return "$" + value;
                            // }
                          }
                        }
                      ],
                      yAxes: [
                        {
                          // barPercentage: 0.7,
                          gridLines: {
                            offsetGridLines: true
                          }
                        }
                      ]
                    }
                  }
                });
              })
            }

            if (this.pageData.lang == 'ch') {
              document.title = this.pageData.participantInfo.fullname + ' - ' + this.$t('title.result');
            } else {
              document.title = this.pageData.participantInfo.englishname + ' - ' + this.$t('title.result');
            }

            this.initTargetRadar();

            return;
          } else if (response.data.code == '210') {
            this.$notify.warning({
              title: this.$t("notify.title_err_type2"),
              message: this.$t('result.notify_questionnaire_isnot_finished'),
              duration: 0
            });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        //look at error
        console.log(error)
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    initTargetRadar() {
      this.$nextTick(function () {
        var ctx_target_radar = document.getElementById("target_radar_chart");
        Chart.defaults.global.defaultFontSize = 15;
        Chart.defaults.global.defaultFontColor = 'red';
        var target_radar_chart_init = new Chart(ctx_target_radar, {
          type: "radar",
          scaleStartValue: 0,
          scaleSteps: 5,
          scaleStepWidth: 1,
          data: {
            //   labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            labels: this.pageData.targetLabelsList,
            datasets: [
              {
                label: this.$t('result.chart_label_self'),
                // backgroundColor: "rgba(103,194,58)",
                borderColor: "rgba(103,194,58)",
                pointBackgroundColor: "rgba(103,194,58)",
                borderWidth: 2,
                pointStrokeColor: "#fff",
                pointStyle: "rot",
                data: this.pageData.targetSelfScoreList,
                cubicInterpolationMode: "monotone",
                spanGaps: "true",
              },
              {
                label: this.$t('result.chart_label_score'),
                // backgroundColor: "rgba(64,158,255)",
                borderColor: "rgba(64,158,255)",
                pointBackgroundColor: "rgba(64,158,255)",
                borderWidth: 2,
                pointStrokeColor: "#fff",
                pointStyle: "rot",
                data: this.pageData.targetScoreList,
                cubicInterpolationMode: "monotone",
                spanGaps: "true",
                // fillColor: "rgba(225,255,255)"
              },
              {
                label: this.$t('result.chart_label_company_avg'),
                // backgroundColor: "rgba(220,20,60)",
                borderColor: "rgba(220,20,60)",
                pointBackgroundColor: "rgba(220,20,60)",
                borderWidth: 2,
                pointStrokeColor: "#fff",
                pointStyle: "rot",
                data: this.pageData.targetCompanyAvgList,
                cubicInterpolationMode: "monotone",
                spanGaps: "true",
                // fillColor: "rgba(220,220,220,0.2)"
              }
            ],
          },
          options: {
            scale: {
              pointLabels: {
                fontSize: 14
              },
              ticks: {
                beginAtZero: true,
                max: 5.00
              }
            }
          }
        });
      })
      this.$router.push({ query: {} });
    },
  }
}
</script>

<style scoped lang="scss">
@media print {
  @page {
    // size: A4;
    // margin: 0;
    // margin-bottom: 1mm;
  }

  .base {
    color: #000 !important;
    word-break: break-word !important;
  }
  .labels_content .el-row {
    margin-left: 20% !important;
  }
  .labels_items h4 {
    color: #000 !important;
  }
  .labels_items a {
    color: #000 !important;
  }
  .text_explain_content .el-row {
    font-size: 18px !important;
  }
  // .target_results_content {
  //   margin-top: 220px !important;
  // }
  .sub_title_content {
    font-size: 30px !important;
    margin-left: 0px !important;
  }
  .target_solve_example_slider {
    width: 100% !important;
  }
  .target_solve_discription .el-row {
    font-size: 18px !important;
  }
  .cognition_discription_content .el-row {
    font-size: 18px !important;
  }
  .cognition_card_content .el-card {
    border: 0.1px solid #222222 !important;
  }
  .advantage_discription .el-row,
  .improvable_discription.el-row,
  .self_rating_discription .el-row,
  .subjective_discription .el-row {
    font-size: 18px !important;
  }
  .fullpage_backup {
    display: none !important;
  }
  // TODO:CSS
  .preface_content,
  .datasource_content,
  .target_radar_content,
  .target_solve_discription,
  .target_solve_items,
  .target_solve_content,
  .cognition_content,
  .behaviours_content,
  .advantage_table_content,
  .subjective_content,
  .self_rating_content {
    page-break-after: always;
  }
}

.base {
  word-break: break-word;
}
.el-table {
  font-size: 17px;
}
.cover_content {
  margin-top: 100px;
}
.coverimage_content {
  width: 300px;
  margin: 0 auto;
}
.labels_content {
  margin-top: 100px;
}
.labels_content span {
  color: #303133;
  font-size: 40px;
  font-weight: bold;
}
.labels_items {
  margin-top: 100px;
}
.labels_items h4 {
  font-size: 25px;
  color: #303133;
  width: 300px;
  font-weight: bold;
  white-space: pre;
}
.labels_items a {
  font-size: 25px;
  margin-left: 40px;
  font-weight: bold;
  color: #303133;
}
.labels_content .el-row {
  line-height: 70px;
  margin-left: 38%;
  font-size: 20px;
}
.preface_content {
  width: 100%;
  margin-top: 80px;
}
.preface_content .el-alert {
  margin-bottom: 25px;
}
.preface_texts_content {
  margin: 0 auto;
  width: 90%;
  text-align: left;
}
.preface_ul li {
  line-height: 60px;
  font-size: 17px;
}
.preface_h4 {
  font-weight: bold;
  font-size: 20px;
}
.tools_content {
  float: right;
}
.discription_content {
  margin-top: 50px;
  width: 100%;
}
.score_level_content {
  margin: 0 auto;
  width: 90%;
}
.text_explain_content {
  margin: 0 auto;
  margin-top: 50px;
  width: 90%;
}
.text_explain_content .el-row {
  line-height: 60px;
  font-size: 17px;
}
.datasource_content {
  margin-top: 30px;
  width: 100%;
}
.weight_content {
  margin: 0 auto;
  margin-top: 10px;
  width: 90%;
}
.target_results_content {
  width: 100%;
  margin-top: 30px;
}
.sub_title_content {
  font-size: 30px;
  text-align: left;
  padding: 30px;
  margin-left: 50px;
  color: #000;
  font-weight: bold;
  // color: #f56c6c;
}
.target_table_content {
  margin: 0 auto;
  width: 90%;
}
.target_radar_content {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-right: 60%;
}
#target_radar_chart {
  margin-left: 55%;
  width: 900px;
  height: 900px;
}
.target_solve_content {
  margin: 0 auto;
  // margin-top: 1000px;
  width: 90%;
}
.target_solve_discription {
  margin-top: 30px;
  font-size: 20px;
}
.target_solve_discription .el-row {
  text-align: left;
  color: #000;
}
.target_solve_example_slider {
  width: 50%;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
}
.target_solve_items {
  // margin-top: 20px;
  margin-bottom: 30px;
}
.target_span_title {
  text-align: left;
  font-size: 23px;
  // font-weight: bold;
}
.cognition_content {
  width: 100%;
  clear: both;
}
.cognition_discription_content,
.cognition_card_content {
  margin: 0 auto;
  width: 90%;
}
.cognition_discription_content .el-row {
  font-size: 16px;
  text-align: left;
  color: #000;
}
.cognition_discription_content ul {
  margin-top: 20px;
  margin-left: 50px;
  line-height: 40px;
  text-align: left;
  color: #000;
  font-size: 16px;
  font-weight: bold;
}
.cognition_card_content .el-card {
  width: 300px;
  height: 370px;
  float: left;
  margin-left: 50px;
  margin-top: 30px;
}
.cognition_card_header {
  font-size: 22px;
  font-weight: bold;
}
.cognition_card_body_li_1,
.cognition_card_body_li_2,
.cognition_card_body_li_3,
.cognition_card_body_li_4 {
  font-size: 15px;
  line-height: 30px;
}
.cognition_card_body_li_1 {
  font-size: 15px;
  color: #67c23a;
}
.cognition_card_body_li_2 {
  font-size: 15px;
  color: #409eff;
}
.cognition_card_body_li_3 {
  font-size: 15px;
  color: #e6a23c;
}
.cognition_card_body_li_4 {
  font-size: 15px;
  color: #f56c6c;
}
.cognition_card_null {
  margin-top: 30px;
  font-size: 15px;
  font-weight: bold;
}
.question_analysis_content {
  margin-top: 20px;
  // margin-bottom: 100px;
}
.question_analysis_content_title {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 17px;
  color: #000;
}
.behaviours_content {
  clear: both;
}
.advantage_discription {
  margin: 0 auto;
  width: 90%;
}
.behaviour_exp_content {
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;
}
.self_exp_item {
  display: inline-block;
  margin: 0 auto;
  width: 70px;
  height: 20px;
  background-color: #eb4600;
  vertical-align: middle;
}
.others_exp_item {
  display: inline-block;
  margin: 0 auto;
  width: 70px;
  height: 20px;
  background-color: #0578f1;
  vertical-align: middle;
}
.improvable_discription {
  margin: 0 auto;
  width: 90%;
  margin-top: 50px;
}
.advantage_discription .el-row {
  text-align: left;
  font-size: 16px;
  color: #000;
}
.improvable_discription .el-row {
  text-align: left;
  font-size: 16px;
  color: #000;
}
.advantage_table_content {
  margin: 0 auto;
  width: 90%;
  margin-top: 30px;
}
.improvable_table_content {
  margin: 0 auto;
  width: 90%;
  margin-top: 30px;
}
canvas[id^="advantage_result_compare_"] {
  width: 150px;
  height: 100px;
}
canvas[id^="improvable_result_compare_"] {
  width: 150px;
  height: 120px;
}
.self_rating_body,
.self_rating_table_content,
.score_table_content {
  margin: 0 auto;
  width: 90%;
}
.self_rating_discription .el-row,
.subjective_discription .el-row {
  text-align: left;
  font-size: 16px;
  color: #000;
  margin-bottom: 30px;
}
.subjective_body {
  margin: 0 auto;
  width: 90%;
}
.subjective_body ul {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  line-height: 80px;
}
.subjective_body ul li {
  font-size: 16px;
  font-weight: normal;
  line-height: 30px;
}
.score_content {
  padding-bottom: 20px;
}
.foot_wrap {
  margin-top: 100px;
}
</style>