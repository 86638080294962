<template>
  <div class="base" style="width: 100%; height: 100%">
    <transition name="el-fade-in-linear">
      <div class="whole_wrap" v-show="show.wholeShow">
        <div class="head_wrap"></div>
        <div class="body_wrap">
          <div class="tips_content"></div>
          <el-result icon="success" :title="$t('logout.title_logout')">
            <template #extra>
              <el-button type="primary" size="medium" @click="toLogin">{{
                $t("logout.btn_tologin")
              }}</el-button>
            </template>
          </el-result>
        </div>
        <div class="foot_wrap"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as utilsjs from '@/assets/js/utils'
import * as mountederjs from '@/assets/js/mounteder'
export default {
  data() {
    return {
      show: {
        wholeShow: false
      },
      pageData: {},
      formData: {}
    };
  },
  mounted() {
    document.title = this.$t('title.logout');
    this.$i18n.locale = sessionStorage.getItem("lang");
    setTimeout(() => { this.show.wholeShow = true }, 1);
    sessionStorage.clear();
  },
  methods: {
    toLogin() {
      let targetLogin = this.$router.resolve({ name: "Login" });
      window.open(targetLogin.href, '_blank');
    }
  }
}
</script>

<style scoped lang="scss">
.tips_content {
  margin-top: 100px;
}
</style>