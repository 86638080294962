<template>
  <div class="base" style="width: 100%; height: 100%">
    <transition name="el-fade-in-linear">
      <div class="whole_wrap" v-show="show.wholeShow">
        <div class="head_wrap"></div>
        <div class="body_wrap">
          <div class="menu_wrap">
            <el-menu
              router
              :default-active="'1'"
              class="main_menu"
              mode="horizontal"
              @select="mainMenuSelect"
              background-color="#323233"
              text-color="#fff"
              active-text-color="#ffd04b"
            >
              <p class="top_title">{{ $t("console.menu_title") }}</p>
              <el-menu-item index="/console/question">{{
                $t("console.menu_question")
              }}</el-menu-item>
              <el-menu-item index="/console/questionnaire">{{
                $t("console.menu_questionnaire")
              }}</el-menu-item>
              <el-menu-item index="/console/activity">{{
                $t("console.menu_activity")
              }}</el-menu-item>
              <el-submenu index="4">
                <template #title>{{ $t("console.menu_properties") }}</template>
                <el-menu-item index="/console/target">{{
                  $t("console.submenu_target")
                }}</el-menu-item>
                <el-menu-item index="/console/dept">
                  {{ $t("console.submenu_dept") }}
                </el-menu-item>
                <el-menu-item index="/console/weight" :disabled="true">
                  {{ $t("console.submenu_weight") }}
                </el-menu-item>
              </el-submenu>
              <el-menu-item index="/console/employee">{{
                $t("console.menu_staff")
              }}</el-menu-item>
              <p class="avatar_content">
                <el-row>{{ $t("text.text_powered_by") }}</el-row>

                <span class="language_wrap">
                  <el-dropdown
                    placement="bottom"
                    :show-timeout="100"
                    @command="setLanguage"
                  >
                    <el-button circle type="primary" size="large">
                      <!-- 语言/Lan<i class="el-icon-arrow-down el-icon--right"></i> -->
                      <i class="el-icon-s-tools"></i>
                    </el-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item command="ch">中文</el-dropdown-item>
                        <el-dropdown-item command="en"
                          >English</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </span>

                <el-dropdown placement="bottom" :show-timeout="100">
                  <el-avatar :size="35">{{ pageData.username }}</el-avatar>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <div class="avatar_dropdown_items_content">
                        <!-- <el-dropdown-item disabled
                        >My information</el-dropdown-item
                      > -->
                        <!-- <el-dropdown-item disabled>Settings</el-dropdown-item> -->
                        <el-dropdown-item>
                          <router-link to="/logout" @click="custLogout">{{
                            $t("console.router_logout")
                          }}</router-link>
                        </el-dropdown-item>
                      </div>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </p>
            </el-menu>
            <router-view />
          </div>
        </div>
        <div class="foot_wrap"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as utilsjs from '@/assets/js/utils'
import * as mountederjs from '@/assets/js/mounteder'
export default {
  data() {
    return {
      show: {
        wholeShow: false
      },
      pageData: {
        username: '',
        userinfo: {}
      },
      formData: {

      }
    };
  },
  mounted() {
    document.title = this.$t('title.console');
    this.pageData.username = sessionStorage.getItem('username');
    this.pageData.userinfo = JSON.parse(sessionStorage.getItem(this.pageData.username));
    if (mountederjs.evaluateM(this)) {
      setTimeout(() => { this.show.wholeShow = true }, 1);
    } else {
      this.$router.push({ name: 'Login' });
    }
  },
  methods: {
    mainMenuSelect(key, keyPath) {
      //   console.log(key, keyPath);
    },
    setLanguage(command) {
      this.$i18n.locale = command;
      sessionStorage.setItem('lang', this.$i18n.locale);
      location.reload();
    },
    custLogout() {
      // sessionStorage.clear();
    }
  }
}
</script>

<style scoped lang="scss">
.body_wrap {
  overflow: auto;
  width: 100%;
}
.top_title {
  font-size: 22px;
  font-weight: bold;
  color: #ffffff;
  opacity: 1;
  margin-left: 30px;
  margin-right: 50px;
  margin-top: 6px;
  float: left;
  padding: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.language_wrap {
  margin-right: 10px;
  vertical-align: middle;
}
.avatar_content {
  display: flex;
  justify-content: end;
  // float: right;
  margin-top: 10px;
  margin-right: 10px;
}
.avatar_content .el-row {
  color: #ffffff;
  margin-right: 15px;
  margin-top: 10px;
}
.avatar_dropdown_items_content a {
  text-decoration: none;
  color: #606266;
}
.menu_wrap {
  user-select: none;
}
.menu_wrap li {
  font-size: 17px;
  font-family: "Microsoft YaHei", "微软雅黑";
  width: 10%;
}
</style>