import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/common/Login'
import Logout from '../views/common/Logout'
import HRNavigation from '../views/common/HRNavigation'
import Chips from '../views/tools/Chips'
import IQTest from '../views/survey/IQTest'
import IQResults from '../views/survey/IQResults'
import BigFiveAskForm from '../views/survey/BigFiveAskForm'
import BigFiveResults from '../views/survey/BigFiveResults'
import LaneSheet from "../views/dingtalk/approval/LaneSheet";
import DTForm from "../views/dingtalk/approval/ApprovalForm";
import Key from '../views/survey/Key'
import NotFound from '../views/common/NotFound'
import Console from '../views/evaluate/management/Console'
import Result from '../views/evaluate/management/Result'
import _ResultPt from '../views/evaluate/management/_ResultPrinting'
import ActivityList from '../views/evaluate/testing/ActivityList'
import AskForm from "../views/evaluate/testing/AskForm";

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/hrnavigation',
    name: 'HRNavigation',
    component: HRNavigation
  },
  {
    path: '/chips',
    name: 'Chips',
    component: Chips
  },
  {
    path: '/iq-test',
    name: 'IQTest',
    component: IQTest
  },
  {
    path: '/iqresults',
    name: 'IQResults',
    component: IQResults
  },
  {
    path: '/bigfiveaskform',
    name: 'BigFiveAskForm',
    component: BigFiveAskForm
  },
  {
    path: '/bigfiveresults',
    name: 'BigFiveResults',
    component: BigFiveResults
  },
  {
    path: '/dingtalk/approval/laneSheet',
    name: 'LaneSheet',
    component: LaneSheet
  },
  {
    path: '/dingtalk/approval/form',
    name: 'DTForm',
    component: DTForm
  },
  {
    path: '/keys',
    name: 'Key',
    component: Key
  },
  {
    path: '/console',
    name: 'Console',
    component: Console,
    meta: {
      requireAuth: true
    },
    children: [{
        path: 'question',
        component: () =>
          import('@/components/evaluate/management/Question')
      },
      {
        path: 'target',
        component: () =>
          import('@/components/evaluate/management/Target')
      },
      {
        path: 'dept',
        component: () =>
          import('@/components/evaluate/management/Dept')
      },
      {
        path: 'questionnaire',
        component: () =>
          import('@/components/evaluate/management/Questionnaire')
      },
      {
        path: 'activity',
        component: () =>
          import('@/components/evaluate/management/Activity')
      },
      {
        path: 'employee',
        component: () =>
          import('@/components/evaluate/management/Staff')
      }
    ]
  },
  {
    path: '/result',
    name: 'Result',
    component: Result
  },
  {
    path: '/_result',
    name: '_ResultPt',
    component: _ResultPt
  },
  {
    path: '/activities',
    name: 'Activities',
    component: ActivityList
  },
  {
    path: '/askform',
    name: 'AskForm',
    component: AskForm
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/:catchAll(.*)*',
    component: NotFound,
    meta: {
      requireAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router