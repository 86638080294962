<template>
  <div
    class="base"
    style="width: 100%; height: 100%"
    v-loading.fullscreen.lock="pageData.waitDngtalkLoading"
    :element-loading-text="$t('login.loadingviadingtalk')"
  >
    <transition name="el-fade-in-linear">
      <div class="whole_wrap" v-show="show.wholeShow">
        <!-- <div class="background">
          <img :src="require('@/assets/images/loginback.jpg')" alt="" />
        </div> -->
        <div class="head_wrap"></div>
        <div class="body_wrap">
          <div class="left_content">
            <!-- <div class="image_content">
              <el-image :src="require('@/assets/images/loginback.jpg')">
                <template #error>
                  <div class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </template>
              </el-image>
            </div> -->
          </div>
          <div class="right_content">
            <div class="language_wrap">
              <el-dropdown
                placement="bottom"
                :show-timeout="100"
                @command="setLanguage"
              >
                <el-button type="success" size="small">
                  中文/English<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item command="ch">中文</el-dropdown-item>
                    <el-dropdown-item command="en">English</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
            <div class="loginform_content">
              <!-- <el-card shadow="always"> -->
              <div class="logincard_img">
                <img :src="require('@/assets/images/ifi.png')" class="image" />
              </div>
              <div class="logincard_form">
                <el-form ref="loginForm" :model="formData.loginForm">
                  <el-form-item>
                    <el-input
                      v-model="formData.loginForm.username"
                      :placeholder="$t('login.placehoder_username')"
                      maxlength="15"
                      show-word-limit
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-input
                      show-password
                      v-model="formData.loginForm.password"
                      :placeholder="$t('login.placehoder_password')"
                      maxlength="15"
                      show-word-limit
                      @keypress.enter="doLogin"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="success"
                      @click="doLogin"
                      :loading="pageData.loginLoading"
                      ><font size="3px">{{
                        $t("login.btn_login")
                      }}</font></el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
              <!-- </el-card> -->
            </div>
          </div>
        </div>
        <div class="foot_wrap">
          <div class="records_content">
            <span
              >&nbsp;&nbsp;<a href="https://beian.miit.gov.cn"
                >ICP : 粤 ICP备 2021172083号 - 1</a
              ></span
            >
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as utilsjs from '@/assets/js/utils'
import {
  onMounted,
  getCurrentInstance
} from 'vue'
export default {
  //   setup(props, context) {


  //     onMounted(() => {
  //       this.context = context;
  //     })
  //   },
  data() {
    return {
      show: {
        wholeShow: false
      },
      pageData: {
        loginLoading: false,
        waitDngtalkLoading: false,
      },
      formData: {
        loginForm: {
          username: '',
          password: ''
        }
      }
    };
  },
  mounted() {
    sessionStorage.clear();
    document.title = this.$t('title.login');
    setTimeout(() => { this.show.wholeShow = true }, 2);
    utilsjs.requestDingAuthCode(this).then((authCode) => {
      if (authCode != null) {
        this.pageData.waitDngtalkLoading = true;
        //in dingtalk, login with it
        this.loginViaDingtalk(authCode);
      } else {
        //not in
      }
    });
    // console.log(this.$api)
  },
  methods: {
    setLanguage(command) {
      this.$i18n.locale = command;
      sessionStorage.setItem('lang', this.$i18n.locale);
    },
    loginViaDingtalk(authCode) {
      let dingtalkLoginRequest = {};
      dingtalkLoginRequest.code = authCode;
      dingtalkLoginRequest.index = process.env.VUE_APP_DINGTALK_TOKEN_INDEX;
      this.$axios({
        method: 'post',
        url: this.$api + '/api/user/loginViaDingtalk',
        data: dingtalkLoginRequest
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.pageData.waitDngtalkLoading = false;
            // this.pageData.custUserinfo.way = 1;
            sessionStorage.setItem('username', response.data.data.username);
            sessionStorage.setItem(response.data.data.username, JSON.stringify(response.data.data));
            if (response.data.data.type == 'evm') {
              this.$router.push({ path: '/console/question' });
            } else if (response.data.data.type == 'evn') {
              this.$router.push({ path: '/activities' });
            } else {
              this.$router.push({ path: '/' });
            }
            return;
          } else if (response.data.code == '210') {
            //not bound
            this.pageData.waitDngtalkLoading = false;
            this.$notify.warning({
              title: this.$t("notify.title_notification"),
              message: this.$t("login.notify_dingtalk_not_bound"),
              duration: 0
            });
            return;
          } else if (response.data.code == '701') {
            //code if out of date
            this.pageData.waitDngtalkLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t("notify.auth_err") + 'OUT_OF_DATE',
              duration: 0
            });
            return;
          } else {
            this.pageData.waitDngtalkLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t("notify.content_refused")
            });
            return;
          }
        } else {
          this.pageData.waitDngtalkLoading = false;
          this.$notify.error({
            title: this.$t("notify.title_server"),
            message: this.$t("notify.content_refused") + response.status
          });
          return;
        }
      }).catch((error) => {
        this.pageData.waitDngtalkLoading = false;
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t("notify.content_refused")
        });
        return;
      });
    },
    doLogin() {
      if (utilsjs.strIsEmpty(this.formData.loginForm.username)) {
        this.$notify.error({
          title: this.$t('common.notity_title_message'),
          message: this.$t('login.notify_username_null')
        });
        return;
      }

      if (utilsjs.strIsEmpty(this.formData.loginForm.password)) {
        this.$notify.error({
          title: this.$t('common.notity_title_message'),
          message: this.$t('login.notify_password_null')
        });
        return;
      }

      if (this.formData.loginForm.username.length > 15 || this.formData.loginForm.password.length > 15) {
        this.$notify.error({
          title: this.$t('common.notity_title_message'),
          message: this.$t('login.notify_usernamepassword_lengtherror')
        });
        return;
      }

      this.pageData.loginLoading = true;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/user/login',
        data: this.formData.loginForm
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200' && response.data.data.username == this.formData.loginForm.username) {
            this.pageData.loginLoading = false;
            sessionStorage.setItem('username', this.formData.loginForm.username);
            sessionStorage.setItem(response.data.data.username, JSON.stringify(response.data.data));
            if (response.data.data.type == 'evm') {
              this.$router.push({ path: '/console/question' });
            } else if (response.data.data.type == 'evn') {
              this.$router.push({ path: '/activities' });
            } else {
              this.$router.push({ path: '/' });
            }
          } else if (response.data.code == '210') {
            //Incorrect username or password
            this.pageData.loginLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_retry"),
              message: this.$t('notify.content_loginerr')
            });
            this.formData.loginForm.password = '';
            return;
          } else {
            //code = 700 and more
            this.pageData.loginLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            this.formData.loginForm.password = '';
            return;
          }
        } else {
          this.pageData.loginLoading = false;
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          this.formData.loginForm.password = '';
          return;
        }
      }).catch((error) => {
        this.pageData.loginLoading = false;
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    }
  }
}
</script>

<style scoped lang="scss">
.base {
  // background-color: #333333;
  overflow: hidden;
}
.whole_wrap {
  width: 100%;
  height: 100%;
}
// .background {
//   width: 100%;
//   height: 100%;
//   z-index: -1;
//   position: absolute;
// }
.language_wrap {
  text-align: end;
  padding: 15px;
}
.body_wrap {
  width: 100%;
  height: 100%;
  display: table;
}
.left_content {
  background-image: url(../../assets/images/alt.png);
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  user-select: none;
}
.right_content {
  float: right;
  // padding: 9%;
  width: 550px;
  height: 100%;
  background-color: #ffffff;
}
.left_content,
.right_content {
  display: table-cell;
}
// .loginform_content {
//   height: 100vh;
// }
.logincard_img img {
  width: 90px;
  margin-top: 200px;
  user-select: none;
  // margin-bottom: 10px;
}
.logincard_form {
  margin-top: 70px;
  margin-bottom: 5px;
}
.logincard_form .el-input {
  width: 350px;
  margin-bottom: 10px;
}
.logincard_form .el-button {
  width: 350px;
}
.records_content {
  color: #ffffff;
  position: fixed;
  bottom: 1vh;
  // right: 2vh;
  right: 49%;
  font-size: 13px;
}
.records_content a {
  color: #ffffff;
}
</style>