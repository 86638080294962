<template>
  <div class="base" style="width: 100%; height: 100%">
    <transition name="el-fade-in-linear">
      <div class="whole_wrap" v-show="show.wholeShow">
        <div class="head_wrap">
          <p>{{ $t("hrnavigation.top_title") }}</p>
        </div>
        <div class="body_wrap">
          <el-button type="danger" size="small" @click="toIqResults">{{
            $t("hrnavigation.btn_toiqresults")
          }}</el-button>
          <el-button type="danger" size="small" @click="toBfResults">{{
            $t("hrnavigation.btn_tobigfiveresults")
          }}</el-button>
          <el-button type="success" size="small" @click="login360">{{
            $t("hrnavigation.btn_to_360")
          }}</el-button>
        </div>
        <div class="foot_wrap"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as utilsjs from '@/assets/js/utils'
import * as mountederjs from '@/assets/js/mounteder'
export default {
  data() {
    return {
      show: {
        wholeShow: false
      },
      _pages: {
        username: '',
        userinfo: {},
        lang: ''
      },
      _forms: {

      }
    };
  },
  mounted() {
    this.$i18n.locale = 'ch';
    document.title = this.$t('title.hrnavigation');
    sessionStorage.setItem('lang', this.$i18n.locale);
    this._pages.lang = sessionStorage.getItem('lang');


    // this._pages.username = sessionStorage.getItem('username');
    // this._pages.userinfo = JSON.parse(sessionStorage.getItem(this._pages.username));

    setTimeout(() => { this.show.wholeShow = true }, 1);

  },
  methods: {
    toIqResults() {
      this.$router.push({
        name: 'IQResults'
      });
    },
    toBfResults() {
      this.$router.push({
        name: 'BigFiveResults'
      });
    },
    login360() {
      this.$router.push({
        name: 'Login'
      });
    }
  }
}
</script>

<style scoped lang="scss">
.head_wrap {
  padding-top: 15vh;
}
.head_wrap p {
  font-size: 25px;
}
.body_wrap {
  padding-top: 10vh;
}
</style>