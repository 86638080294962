import {
    createApp,
    Vue
} from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus';
import {
    ElMessage
} from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css';
import * as echarts from 'echarts';
import VueI18n from './language'
import axios from 'axios'
import router from './router'
import store from './store'
import Print from 'vue3-print-nb'
import 'lib-flexible'
import './assets/css/base.css'
import * as dd from 'dingtalk-jsapi'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueI18n)
app.use(Print)
app.use(ElementPlus)
app.use(ElementPlus, {
    VueI18n: VueI18n.global.t,
})


app.config.globalProperties.$echarts = echarts
app.config.globalProperties.$axios = axios
app.config.globalProperties.$dd = dd
app.config.globalProperties.$ElMessage = ElMessage
// app.config.globalProperties.$api = ''
// app.config.globalProperties.$api = 'http://120.24.247.167:8899'
// app.config.globalProperties.$api = 'http://192.168.1.241:8899'
app.config.globalProperties.$api = process.env.VUE_APP_API_PREFIX;

// axios.defaults.baseURL = '/'
axios.defaults.headers = {
    'Content-Type': 'application/json;charset=UTF-8'
}

app.mount('#app')