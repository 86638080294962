<template>
  <div class="base" style="width: 100%; height: 100%">
    <transition name="el-fade-in-linear">
      <div
        class="whole_wrap"
        v-show="show.wholeShow"
        v-loading.fullscreen.lock="_pages.fullLoading"
      >
        <div class="head_wrap">
          <!-- <el-alert>{{ _pages.keys.length }}</el-alert> -->
          <div class="statistics_content">
            <div class="available_c">
              <el-progress
                type="circle"
                :percentage="Number(_pages.akeys * 100)"
                status="success"
                :width="110"
                >{{ _pages.akeys * _pages.keys.length }}</el-progress
              >
              <div class="unused_progress" style="color: #13ce66">
                {{ $t("text.unused") }}
              </div>
            </div>
            <div class="inusing_c">
              <el-progress
                type="circle"
                :percentage="Number(_pages.ikeys * 100)"
                status="warning"
                :width="110"
                >{{ _pages.ikeys * _pages.keys.length }}</el-progress
              >
              <div class="inusing_progress" style="color: #e6a23c">
                {{ $t("text.inusing") }}
              </div>
            </div>
            <div class="expired_c">
              <el-progress
                type="circle"
                :percentage="Number(_pages.ekeys * 100)"
                status="exception"
                :width="110"
                >{{ _pages.ekeys * _pages.keys.length }}</el-progress
              >
              <div class="expired_progress" style="color: #f56c6c">
                {{ $t("text.used") }}
              </div>
            </div>
          </div>
        </div>
        <div class="body_wrap">
          <div class="key_table_content">
            <el-table
              height="68.5vh"
              :data="_pages.availbleKeys"
              stripe
              border
              :header-cell-style="{
                textAlign: 'center',
                backgroundColor: '#dcdfe6',
              }"
              :cell-style="{ textAlign: 'center' }"
            >
              <el-table-column width="70" sortable prop="id">
                <template #default="scope">
                  {{ scope.row.id }}
                </template></el-table-column
              >

              <el-table-column :label="$t('text.keyword')">
                <template #default="scope">
                  {{ scope.row.pwd }}
                </template>
              </el-table-column>

              <el-table-column
                :label="$t('text.status')"
                sortable
                prop="status"
              >
                <template #default="scope">
                  <span v-if="scope.row.status == 1" style="color: #13ce66">{{
                    $t("text.unused")
                  }}</span>
                  <span
                    v-else-if="scope.row.status == 2"
                    style="color: #e6a23c"
                    >{{ $t("text.inusing") }}</span
                  >
                  <span
                    v-else-if="scope.row.status == 3"
                    style="color: #f56c6c"
                    >{{ $t("text.used") }}</span
                  >
                  <!-- <span v-else>-</span> -->
                </template>
              </el-table-column>

              <el-table-column :label="$t('text.option')">
                <template #default="scope">
                  <el-button
                    circle
                    class="el-icon-refresh"
                    type="success"
                    size="mini"
                    @click="resetKey(scope.row)"
                    :disabled="scope.row.status !== 2"
                  ></el-button>
                  <el-button
                    circle
                    class="el-icon-delete"
                    type="danger"
                    size="mini"
                    @click="expireKey(scope.row)"
                    :disabled="scope.row.status !== 2"
                  ></el-button
                ></template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="foot_wrap"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as utilsjs from '@/assets/js/utils'
import * as mountederjs from '@/assets/js/mounteder'
export default {
  data() {
    return {
      show: {
        wholeShow: false
      },
      _pages: {
        lang: '',
        fullLoading: true,
        keys: [],
        availbleKeys: [],
        akeys: 0,
        ikeys: 0,
        ekeys: 0
      },
      _forms: {

      }
    };
  },
  mounted() {
    if (utilsjs.strIsEmpty(this.$route.query.q)) {
      this.$i18n.locale = 'ch';
    } else {
      this.$i18n.locale = this.$route.query.q;
    }
    document.title = this.$t('title.keys');
    sessionStorage.setItem('lang', this.$i18n.locale);
    this._pages.lang = sessionStorage.getItem('lang');

    this.queryKeys();

    setTimeout(() => { this.show.wholeShow = true }, 1);
  },
  methods: {
    queryKeys() {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/survey/queryKeys'
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this._pages.keys = response.data.data;

            for (let index in this._pages.keys) {
              if (this._pages.keys[index].status == 1) {
                this._pages.akeys++;
                this._pages.availbleKeys.push(this._pages.keys[index]);
              } else if (this._pages.keys[index].status == 2) {
                this._pages.ikeys++;
                this._pages.availbleKeys.push(this._pages.keys[index]);
              } else if (this._pages.keys[index].status == 3) {
                this._pages.ekeys++;
                // this._pages.keys.splice(index, 1);
              }
            }


            this._pages.akeys = this._pages.akeys / this._pages.keys.length;
            this._pages.ikeys = this._pages.ikeys / this._pages.keys.length;
            this._pages.ekeys = this._pages.ekeys / this._pages.keys.length;

            this._pages.fullLoading = false;

            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    resetKey(row) {
      this.$confirm(this.$t("text.reset_confirm_body"), this.$t("text.text_confirm_title"), {
        confirmButtonText: this.$t("common.btn_message_confirm"),
        cancelButtonText: this.$t("common.btn_message_cancel"),
        closeOnClickModal: false,
        closeOnPressEscape: false,
        type: 'warning'
      }).then(() => {
        this.updateKey(row, 1);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t("text.text_canceled")
        });
      });
    },
    expireKey(row) {
      this.$confirm(this.$t("text.expire_confirm_body"), this.$t("text.text_confirm_title"), {
        confirmButtonText: this.$t("common.btn_message_confirm"),
        cancelButtonText: this.$t("common.btn_message_cancel"),
        closeOnClickModal: false,
        closeOnPressEscape: false,
        type: 'warning'
      }).then(() => {
        this.updateKey(row, 2);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t("text.text_canceled")
        });
      });
    },
    updateKey(key, action) {
      if (action === 1) {
        key.status = 1;
      } else if (action === 2) {
        key.status = 3;
      } else {
        return;
      }
      key.way = 4;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/survey/updateKey',
        data: key
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.$notify.success({
              title: this.$t("notify.title_notification"),
              message: this.$t('notify.content_success')
            });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
  }
}
</script>

<style scoped lang="scss">
.statistics_content {
  padding-left: 37vw;
  padding-top: 5vh;
  padding-bottom: 5vh;
}
.available_c,
.inusing_c,
.expired_c {
  display: table-cell;
  padding-left: 20px;
}
.key_table_content {
  width: 90vw;
  margin: 0 auto;
  padding-bottom: 5vh;
}
</style>