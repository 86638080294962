<template>
  <div class="base" style="width: 100%; height: 100%">
    <transition name="el-fade-in-linear">
      <div class="whole_wrap" v-show="show.wholeShow">
        <div class="head_wrap">
          <div class="tips_content" v-show="true">
            <!-- <el-alert
              :title="$t('iqtest.tip_top1')"
              type="success"
              :closable="false"
              show-icon
            />
            <el-alert
              :title="$t('iqtest.tip_top2')"
              type="success"
              :closable="false"
              show-icon
            /> -->
            <div class="title_content" v-if="_pages._tflg">
              {{ $t("iqtest.title_iqs") }}
            </div>
          </div>
          <div class="ask_info_content">
            <!-- v-show="false" -->
            <el-dialog
              v-model="_pages.ageDialogVisible"
              :title="$t('iqtest.title_iqs')"
              width="80%"
              destroy-on-close
              center
              :close-on-click-modal="false"
              :close-on-press-escape="false"
              :show-close="false"
            >
              <div class="info_content">
                <span>{{ $t("iqtest.content_title_ask_age") }}</span>
                <el-form
                  :model="_forms.baseInfo"
                  ref="baseInfoForm"
                  class="baseInfoForm"
                  size="mini"
                >
                  <div class="ask_age_radios">
                    <el-form-item>
                      <el-radio-group v-model="_forms.baseInfo.age">
                        <div class="ask_age_radio_items">
                          <el-row
                            ><el-radio label="1">{{
                              $t("iqtest.age_1")
                            }}</el-radio></el-row
                          >
                          <el-row
                            ><el-radio label="2">{{
                              $t("iqtest.age_2")
                            }}</el-radio></el-row
                          >
                          <el-row
                            ><el-radio label="3">{{
                              $t("iqtest.age_3")
                            }}</el-radio></el-row
                          >
                          <el-row
                            ><el-radio label="4">{{
                              $t("iqtest.age_4")
                            }}</el-radio></el-row
                          >
                          <el-row
                            ><el-radio label="5">{{
                              $t("iqtest.age_5")
                            }}</el-radio></el-row
                          >
                          <el-row
                            ><el-radio label="6">{{
                              $t("iqtest.age_6")
                            }}</el-radio></el-row
                          >
                          <el-row
                            ><el-radio label="7">{{
                              $t("iqtest.age_7")
                            }}</el-radio></el-row
                          >
                        </div>
                      </el-radio-group>
                    </el-form-item>
                  </div>
                  <div class="base_info">
                    <el-form-item :label="$t('bigfive.label_fullname')">
                      <el-input
                        v-model="_forms.baseInfo.fullname"
                        maxlength="20"
                        show-word-limit
                        size="medium"
                        :placeholder="$t('text.placeholder')"
                      ></el-input
                    ></el-form-item>
                    <el-form-item :label="$t('bigfive.label_post')">
                      <el-input
                        v-model="_forms.baseInfo.post"
                        maxlength="20"
                        show-word-limit
                        size="medium"
                        :placeholder="$t('text.placeholder')"
                      ></el-input
                    ></el-form-item>
                  </div>
                </el-form>
              </div>
              <template #footer>
                <span class="dialog-footer">
                  <el-button type="success" @click="saveAge">{{
                    $t("common.btn_message_confirm")
                  }}</el-button>
                </span>
              </template>
            </el-dialog>
          </div>
        </div>

        <div class="keys_wrap" v-if="show.keysShow">
          <el-dialog
            v-model="_pages.keyDVisble"
            :title="$t('text.key_title')"
            width="50vw"
            destroy-on-close
            center
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
          >
            <div>
              <el-input
                v-model="_pages.userkey"
                maxlength="8"
                show-word-limit
                size="medium"
              ></el-input>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button type="success" @click="verifyKey">{{
                  $t("common.btn_message_confirm")
                }}</el-button>
              </span>
            </template>
          </el-dialog>
        </div>

        <div class="body_wrap" v-if="show.bodyShow">
          <div class="question_content" v-if="_pages._tflg">
            <div
              v-for="(qs, index) in _pages.questions"
              :key="index"
              :index="index"
              class="question_body"
            >
              <div class="qs_items">{{ index + 1 + "." + qs.content }}</div>
              <p v-if="qs.has_img === 1">
                <el-image
                  :src="require('@/assets/images/iq/q_' + qs.id + '_sor.png')"
                >
                  <template #error>
                    <div class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div> </template
                ></el-image>
              </p>
              <el-radio-group v-model="_forms.scores[index]">
                <div
                  v-for="(selection, sindex) in qs.selections"
                  :key="sindex"
                  :index="sindex"
                  class="s_items"
                >
                  <el-radio
                    size="large"
                    @change="updateQsProgress"
                    :label="selection.sort + '-' + selection.correct"
                    ><span class="ops_titile">{{
                      selection.content
                    }}</span></el-radio
                  >
                </div>
              </el-radio-group>
            </div>
            <div class="submit">
              <el-button type="success" @click="finishAskform">{{
                $t("iqtest.btn_submit")
              }}</el-button>
            </div>
          </div>
          <div class="result_content" v-if="!_pages._tflg">
            <!-- {{ $t("iqtest.text_rs_score") }} -->
            <p>{{ _pages.stotal }}</p>
          </div>
        </div>
        <div class="foot_wrap">
          <el-backtop target=".base" :right="50"></el-backtop>
          <div class="ifi_texts">
            <span>{{ $t("text.text_powered_by") }}</span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as utilsjs from '@/assets/js/utils'
import * as mountederjs from '@/assets/js/mounteder'
export default {
  data() {
    return {
      show: {
        keysShow: true,
        bodyShow: false,
        wholeShow: false
      },
      _pages: {
        lang: '',
        ageDialogVisible: true,
        questions: [],
        _tflg: true,
        total: 0,
        stotal: 0,
        timer: null,
        timeDuration: 0,
        userkey: '',
        keyDVisble: true
      },
      _forms: {
        baseInfo: {
          age: 0,
          fullname: '',
          post: ''
        },
        scores: []
      }
    };
  },
  created() {
    this.$nextTick(() => {
      // document.oncontextmenu = new Function('event.returnValue=false');
      // document.onkeydown = new Function('event.returnValue=false');
      this.stopDebugger();
    })
  },
  mounted() {
    this.$i18n.locale = 'ch';
    document.title = this.$t('title.iqtest');
    sessionStorage.setItem('lang', this.$i18n.locale);
    this._pages.lang = sessionStorage.getItem('lang');
    setTimeout(() => { this.show.wholeShow = true }, 1);

    this._forms.scores = new Array(60).fill('');

    this.initQs();

  },
  methods: {
    stopDebugger() {
      document.onkeydown = function (e) {
        let evt = window.event || e;
        let code = evt.keyCode || evt.which;
        if (code > 111 && code < 124) {
          if (evt.preventDefault) {
            evt.preventDefault();
          } else {
            evt.keyCode = 0;
            evt.returnValue = false;
          }
        }
      };
      document.oncontextmenu = new Function('event.returnValue=false')
    },
    saveAge() {
      if (this._forms.baseInfo.age == 0) {
        this.$notify.warning({
          title: this.$t("notify.title_notification"),
          message: this.$t('iqtest.notify_age_unselected')
        });
        return;
      }
      if (utilsjs.strIsEmpty(this._forms.baseInfo.fullname) || utilsjs.strIsEmpty(this._forms.baseInfo.post)) {
        this.$notify.warning({
          title: this.$t("notify.title_notification"),
          message: this.$t('bigfive.notify_ask_unfinished')
        });
        return;
      }
      this._pages.ageDialogVisible = false;

      this._pages.timer = setInterval(() => { this._pages.timeDuration++ }, 1000);
    },
    initQs() {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/survey/queryIqQs'
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this._pages.questions = response.data.data;

            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    updateQsProgress() {

    },
    finishAskform() {
      let qindex = 0;
      for (let i in this._forms.scores) {
        if (utilsjs.strIsEmpty(this._forms.scores[i])) {
          qindex = Number(i) + 1;
          this.$notify.warning({
            title: this.$t('notify.title_notification'),
            message: this.$t('iqtest.notify_unfinished_number1') + qindex + this.$t('iqtest.notify_unfinished_number2')
          });
          return;
        }
      }

      for (let i in this._forms.scores) {
        //get score
        this._forms.scores[i] = this._forms.scores[i].split('-')[1];
        if (this._forms.scores[i] == '1') {
          this._pages.total++;
        }
      }

      //total score
      // 0-42
      this._pages.stotal = this._pages.total * 2 + 42;

      //post to save
      this.postToSaveInfo();
    },
    postToSaveInfo() {
      if (utilsjs.strIsEmpty(this._pages.userkey)) {
        //irr
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_ill')
        });
        return;
      }

      let _key = {};
      _key.pwd = this._pages.userkey;
      _key.way = 1;

      let info = {};
      info.name = this._forms.baseInfo.fullname;
      info.post = this._forms.baseInfo.post;
      info.age = this._forms.baseInfo.age;
      info.duration = this._pages.timeDuration;
      info.score = this._pages.stotal;

      info.key = _key;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/survey/createIqRecord',
        data: info
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            //set display
            this._pages._tflg = false;

            clearInterval(this._pages.timer);
            // this._forms.scores = [];
            // this._pages.stotal = 0;
            // this._pages.total = 0;

            return;
          } else if (response.data.code == '701') {
            this.$notify.error({
              title: this.$t("notify.title_err_type1"),
              message: this.$t('notify.content_ill')
            });


            setTimeout(() => {
              this._pages._tflg = true;
            }, 1);
            return;

          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    verifyKey() {
      if (utilsjs.strIsEmpty(this._pages.userkey)) {
        return;
      }
      if (this._pages.userkey.length != 6) {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_key_err')
        });
        return;
      }
      let _key = {};
      _key.pwd = this._pages.userkey;
      this.$axios({
        method: 'post',
        url: this.$api + '/api/survey/verifyKey',
        data: _key
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.show.keysShow = false;
            this.show.bodyShow = true;
            return;
          } else if (response.data.code == '210') {
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_key_err')
            });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    }
  }
}
</script>

<style scoped lang="scss">
.base {
  overflow: auto;
}
.whole_wrap {
  width: 60%;
  margin: 0 auto;
}
.head_wrap {
  width: 100%;
}
.title_content {
  font-size: 35px;
  font-weight: bold;
}
.tips_content {
  margin-top: 50px;
}
.tips_content .el-alert {
  margin-bottom: 20px;
}
.ask_age_radio_items .el-radio {
  margin-top: 30px;
}
.dialog-footer button:first-child {
  margin-right: 10px;
}
.base_info {
  padding-top: 20px;
}
.info_content {
  width: 50%;
  margin: 0 auto;
  font-size: 17px;
  font-family: "Microsoft YaHei", "微软雅黑";
}
.base_info .el-input,
.ask_age_radios {
  width: 70%;
}
.question_content {
  padding-bottom: 5vh;
}
.question_body {
  text-align: left;
  // line-height: 7vh;
  padding-top: 5vh;
}
.qs_items {
  font-size: 20px;
  font-family: "Microsoft YaHei", "微软雅黑";
}
.s_items {
  padding-top: 1.55vh;
}
.ops_titile {
  font-size: 17px;
  font-family: "Microsoft YaHei", "微软雅黑";
}
.submit {
  padding-top: 10vh;
}
.result_content {
  padding-top: 20vh;
  font-size: 40px;
  font-family: "Microsoft YaHei", "微软雅黑";
  font-weight: bold;
  color: #67a23c;
}
.result_content p {
  font-size: 200px;
  padding-top: 5vh;
  font-family: "Microsoft YaHei", "微软雅黑";
}
.ifi_texts {
  position: fixed;
  bottom: 20px;
  right: 5vw;
  font-size: 15px;
  user-select: none;
  color: #67a23c;
}
</style>