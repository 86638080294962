<template>
  <div class="base" style="width: 100%; height: 100%" v-show="show.wholeShow">
    <transition name="el-fade-in-linear">
      <div class="approvalform_container">
        <div class="head_container">
          <el-image :src="require('@/assets/images/ifi.png')"
            ><template #error>
              <div class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div> </template
          ></el-image>
          <div class="more_pic">
            <div class="el-icon-more"></div>
          </div>
          <el-image :src="require('@/assets/images/dingtalk1.jpg')"
            ><template #error>
              <div class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div> </template
          ></el-image>
        </div>

        <div class="top_container">
          <div class="tools_wrap">
            <el-radio-group
              v-model="_forms.approvalForm.type"
              size="medium"
              @change="formChanged(_forms.approvalForm.type)"
            >
              <el-badge
                :value="_pages.form.transfers.length"
                type="danger"
                class="badge_item"
              >
                <el-radio-button :label="1">{{
                  $t("lanesheet.transfer_application")
                }}</el-radio-button>
              </el-badge>
              <el-badge
                :value="_pages.form.jobTransfers.length"
                type="danger"
                class="badge_item"
              >
                <el-radio-button :label="2">{{
                  $t("lanesheet.job_transfer_application")
                }}</el-radio-button>
              </el-badge>
              <el-badge
                :value="_pages.form.resignationHandovers.length"
                type="danger"
                class="badge_item"
              >
                <el-radio-button :label="3">{{
                  $t("lanesheet.resignation_handover_report")
                }}</el-radio-button>
              </el-badge>
            </el-radio-group>
          </div>
        </div>

        <div class="body_container">
          <div class="form_wrap">
            <div class="ta" v-if="_forms.approvalForm.type === 1">
              <el-table
                :data="
                  _pages.results.slice(
                    (_pages.pagination.currentPage - 1) * 13,
                    _pages.pagination.currentPage * 13
                  )
                "
                stripe
                border
                :header-cell-style="{
                  textAlign: 'center',
                  backgroundColor: '#e4e7ed',
                }"
                :cell-style="{ textAlign: 'center' }"
                :default-sort="{ prop: 'created_time', order: 'descending' }"
              >
                <el-table-column :label="$t('lanesheet.employee')">
                  <template #default="scope">
                    {{ scope.row.employee_name }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('lanesheet.department')">
                  <template #default="scope">
                    {{ scope.row.department }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('lanesheet.dateOfEntry')">
                  <template #default="scope">
                    {{ scope.row.entry_date }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('lanesheet.transferDate')">
                  <template #default="scope">
                    {{ scope.row.transfer_date }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('lanesheet.created_time')">
                  <template #default="scope">
                    {{ scope.row.created_time }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('lanesheet.checked')">
                  <template #default="scope">
                    <el-button
                      circle
                      type="success"
                      size="small"
                      @click="updateStatus(1, scope.row, scope.$index)"
                    >
                      <i class="el-icon-check"></i>
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="jta" v-else-if="_forms.approvalForm.type === 2">
              <el-table
                :data="
                  _pages.results.slice(
                    (_pages.pagination.currentPage - 1) * 13,
                    _pages.pagination.currentPage * 13
                  )
                "
                stripe
                border
                :header-cell-style="{
                  textAlign: 'center',
                  backgroundColor: '#dcdfe6',
                }"
                :cell-style="{ textAlign: 'center' }"
                :default-sort="{ prop: 'created_time', order: 'descending' }"
              >
                <el-table-column :label="$t('lanesheet.employee')">
                  <template #default="scope">
                    {{ scope.row.employee_name }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('lanesheet.newdept')">
                  <template #default="scope">
                    {{ scope.row.new_department }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('lanesheet.newtitle')">
                  <template #default="scope">
                    {{ scope.row.new_title }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('lanesheet.dateOfTransferred')">
                  <template #default="scope">
                    {{ scope.row.transferred_date }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('lanesheet.approver1')">
                  <template #default="scope">
                    {{ scope.row.original_approver }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('lanesheet.approver2')">
                  <template #default="scope">
                    {{ scope.row.transferred_approver }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('lanesheet.reason')">
                  <template #default="scope">
                    {{ scope.row.reson_for_transfer }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('lanesheet.created_time')">
                  <template #default="scope">
                    {{ scope.row.created_time }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('lanesheet.checked')">
                  <template #default="scope">
                    <el-button
                      circle
                      type="success"
                      size="small"
                      @click="updateStatus(2, scope.row, scope.$index)"
                    >
                      <i class="el-icon-check"></i>
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="rhr" v-else-if="_forms.approvalForm.type === 3">
              <el-table
                :data="
                  _pages.results.slice(
                    (_pages.pagination.currentPage - 1) * 13,
                    _pages.pagination.currentPage * 13
                  )
                "
                stripe
                border
                :header-cell-style="{
                  textAlign: 'center',
                  backgroundColor: '#dcdfe6',
                }"
                :cell-style="{ textAlign: 'center' }"
                :default-sort="{ prop: 'created_time', order: 'descending' }"
              >
                <el-table-column :label="$t('lanesheet.employee')">
                  <template #default="scope">
                    {{ scope.row.employee_name }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('lanesheet.superior')">
                  <template #default="scope">
                    {{ scope.row.superior_name }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('lanesheet.created_time')">
                  <template #default="scope">
                    {{ scope.row.created_time }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('lanesheet.checked')">
                  <template #default="scope">
                    <el-button
                      circle
                      type="success"
                      size="small"
                      @click="updateStatus(3, scope.row, scope.$index)"
                    >
                      <i class="el-icon-check"></i>
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="none" v-else>Nothing here!</div>

            <div class="_tb_pagination">
              <el-pagination
                background
                @current-change="paginationCurrentChange"
                :page-size="13"
                :current-page="_pages.pagination.currentPage"
                layout="prev, pager, next"
                :total="_pages.results.length"
              />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: {
        wholeShow: false
      },
      _pages: {
        lang: '',
        form: {
          transfers: [],
          jobTransfers: [],
          resignationHandovers: [],
        },
        results: [],
        pagination: {
          currentPage: 1
        }
      },
      _forms: {
        approvalForm: {
          type: 1,
        },
      }
    };
  },
  mounted() {
    this.$i18n.locale = 'ch';
    document.title = this.$t('title.approvalform');
    sessionStorage.setItem('lang', this.$i18n.locale);
    this._pages.lang = sessionStorage.getItem('lang');
    setTimeout(() => { this.show.wholeShow = true }, 1);

    this.queryForms();
  },
  methods: {
    queryForms() {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/dingtalk/re/approval/query'
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {


            this._pages.form.transfers = response.data.data.transfer;
            this._pages.form.jobTransfers = response.data.data.jobTransfer;
            this._pages.form.resignationHandovers = response.data.data.resignationHandover;

            this._pages.results = JSON.parse(JSON.stringify(this._pages.form.transfers));

            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });

            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });

          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });

        return;
      });
    },
    formChanged(val) {
      this._pages.pagination.currentPage = 1;
      switch (val) {
        case 1:
          this._pages.results = JSON.parse(JSON.stringify(this._pages.form.transfers));
          break;

        case 2:
          this._pages.results = JSON.parse(JSON.stringify(this._pages.form.jobTransfers));
          break;

        case 3:
          this._pages.results = JSON.parse(JSON.stringify(this._pages.form.resignationHandovers));
          break;

        default:
          this._pages.results = [];
          break;
      }

    },
    updateStatus(type, row, index) {
      let payload = {};
      payload.type = type;
      switch (type) {
        case 1:
          payload.transfer = JSON.parse(JSON.stringify(row));
          break;

        case 2:
          payload.jobTransfer = JSON.parse(JSON.stringify(row));
          break;

        case 3:
          payload.resignationHandover = JSON.parse(JSON.stringify(row));
          break;

        default:
          break;
      }
      this.$axios({
        method: 'post',
        url: this.$api + '/api/dingtalk/re/approval/status/update',
        data: payload
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {

            this.$ElMessage.success({
              message: this.$t("notify.content_success"),
              type: 'success'
            });

            this._pages.results.splice(index, 1);

            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });

    },
    paginationCurrentChange(currentPage) {
      //current page
      this._pages.pagination.currentPage = currentPage;
    },
  }
}
</script>

<style scoped lang="scss">
.head_container {
  padding-top: 5vh;
  margin-bottom: 3vh;
}
.head_container .el-image {
  width: 40px;
}
.more_pic {
  color: #409eff;
  display: inline-block;
}
.more_pic div {
  vertical-align: middle;
  width: 3vw;
  font-size: 30px;
  margin-top: -2vh;
}
.form_wrap {
  width: 60vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3vh;
}
._tb_pagination {
  position: fixed;
  bottom: 3vh;
  right: 20vw;
}
// .badge_item {
//   margin-top: 10px;
//   margin-right: 30px;
// }
</style>