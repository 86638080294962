<template>
  <div class="base" style="width: 100%; height: 100%">
    <transition name="el-fade-in-linear">
      <div class="whole_wrap" v-show="show.wholeShow">
        <div class="head_wrap">
          <p>
            <span class="language_wrap">
              <el-dropdown
                placement="bottom"
                :show-timeout="100"
                @command="setLanguage"
              >
                <el-button plain circle type="info" size="medium">
                  <!-- 语言/Lan<i class="el-icon-arrow-down el-icon--right"></i> -->
                  <i class="el-icon-s-tools"></i>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item command="ch">中文</el-dropdown-item>
                    <el-dropdown-item command="en">English</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </span>
            <span class="user_content">
              <!-- {{ $t("activitylist.welcome_prefix") }}
            <span v-if="pageData.lang == 'ch'">{{
              pageData.userinfo.fullname
            }}</span>
            <span v-else>{{ pageData.userinfo.englishname }}</span> -->
              <el-dropdown placement="bottom" :show-timeout="100">
                <el-avatar :size="32">{{ pageData.username }}</el-avatar>
                <template #dropdown>
                  <el-dropdown-menu>
                    <div class="avatar_dropdown_items_content">
                      <el-dropdown-item>
                        <router-link to="" @click="openResultsDialog">{{
                          $t("activitylist.router_result")
                        }}</router-link>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <router-link to="/logout" @click="custLogout">{{
                          $t("console.router_logout")
                        }}</router-link>
                      </el-dropdown-item>
                    </div>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </span>
          </p>
          <div
            class="complete_content"
            v-if="
              pageData.completeCount === pageData.activitiesList.length &&
              pageData.completeCount !== 0
            "
          >
            <el-alert
              type="success"
              effect="dark"
              :description="$t('activitylist.conplete_all_questionnaire_alert')"
              show-icon
              :closable="false"
            >
            </el-alert>
          </div>
        </div>
        <div class="body_wrap">
          <div class="main_wrap">
            <div v-if="pageData.activitiesList.length > 0">
              <div class="notice_content">
                <!-- <el-alert
                  type="info"
                  effect="dark"
                  :description="
                    $t('activitylist.activity_alert1') +
                    pageData.activitiesList.length +
                    $t('activitylist.activity_alert2')
                  "
                  show-icon
                  :closable="false"
                >
                </el-alert> -->
                <div
                  style="
                    font-size: 13px;
                    text-align: left;
                    color: #707070;
                  "
                >
                  {{
                    $t("activitylist.activity_alert1") +
                    pageData.activitiesList.length +
                    $t("activitylist.activity_alert2")
                  }}
                </div>
              </div>
              <div class="activities_content">
                <el-table
                  :data="pageData.activitiesList"
                  :header-cell-style="{
                    textAlign: 'center',
                    backgroundColor: '#f2f6fc',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    color: '#606266',
                  }"
                  :cell-style="{ textAlign: 'center', padding: '7px' }"
                >
                  <el-table-column type="index"> </el-table-column>

                  <el-table-column
                    :label="$t('activitylist.label_activity')"
                    v-if="pageData.clientWidth > 600"
                  >
                    <template #default="scope">
                      <span v-if="pageData.lang == 'ch'">{{
                        scope.row.name
                      }}</span>
                      <span v-else>{{ scope.row.name_en }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    :label="$t('activitylist.label_activity')"
                    :width="130"
                    v-else
                  >
                    <template #default="scope">
                      <span v-if="pageData.lang == 'ch'">{{
                        scope.row.name
                      }}</span>
                      <span v-else>{{ scope.row.name_en }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    :label="$t('activitylist.label_be_evaluate')"
                  >
                    <template #default="scope">
                      <span v-if="pageData.lang == 'ch'">{{
                        scope.row.fullname
                      }}</span>
                      <span v-else>{{ scope.row.englishname }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('activitylist.label_type')">
                    <template #default="scope">
                      <span v-if="scope.row.level === 0">
                        {{ $t("activitylist.type_superior") }}
                      </span>
                      <span v-else-if="scope.row.level === 1">
                        {{ $t("activitylist.type_equal") }}
                      </span>
                      <span v-else-if="scope.row.level === 2">
                        {{ $t("activitylist.type_subordinate") }}
                      </span>
                      <span v-else-if="scope.row.level === 3">
                        {{ $t("activitylist.type_self") }}
                      </span>
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('activitylist.label_status')">
                    <template #default="scope">
                      <span
                        v-if="scope.row.isreport === 0"
                        style="color: #e6a23c"
                        >{{ $t("text.text_incomplete") }}</span
                      >
                      <span v-else style="color: #67c23a">{{
                        $t("text.text_complete")
                      }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('activitylist.label_options')">
                    <template #default="scope">
                      <el-button
                        circle
                        type="text"
                        size="mini"
                        @click="goEvaluation(scope.row)"
                        :disabled="scope.row.isreport === 1"
                        ><font size="2vw">{{
                          $t("activitylist.label_options")
                        }}</font></el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div v-else>
              <el-empty
                :description="$t('activitylist.empty_activity_discription')"
                :image-size="300"
              >
                <el-button type="success" @click="reload" size="small">{{
                  $t("text.btn_refresh")
                }}</el-button>
              </el-empty>
            </div>
            <div class="result_content">
              <el-dialog
                width="30%"
                :title="$t('activitylist.title_result')"
                v-model="pageData.resultVisible"
                ref="resultDialog"
                :modal="true"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :center="true"
              >
                <el-alert
                  type="success"
                  effect="dark"
                  :description="$t('activitylist.alert_resulttable')"
                  show-icon
                  :closable="false"
                >
                </el-alert>
                <el-table
                  ref="resultTable"
                  :header-cell-style="{
                    textAlign: 'left',
                    backgroundColor: '#f2f6fc',
                  }"
                  :cell-style="{ textAlign: 'left' }"
                  :data="pageData.resultList"
                >
                  <el-table-column :label="$t('activity.label_activity_title')">
                    <template #default="scope">
                      <span v-if="pageData.lang == 'ch'">{{
                        scope.row.fullname
                      }}</span>

                      <span v-else-if="pageData.lang == 'en'">
                        {{ scope.row.englishname }}</span
                      >
                      <span v-else> {{ scope.row.englishname }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('activitylist.label_option')">
                    <template #default="scope">
                      <el-button
                        @click="showResult(scope.row)"
                        type="success"
                        circle
                        size="small"
                        class="el-icon-s-data"
                      ></el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-dialog>
            </div>
          </div>
        </div>
        <div class="foot_wrap"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as utilsjs from '@/assets/js/utils'
import * as mountederjs from '@/assets/js/mounteder'
export default {
  data() {
    return {
      show: {
        wholeShow: false
      },
      pageData: {
        username: '',
        userinfo: {},
        lang: '',
        activitiesList: [],
        completeCount: 0,
        resultVisible: false,
        resultList: [],
        clientWidth: 0
      },
      formData: {

      }
    };
  },
  mounted() {
    this.pageData.username = sessionStorage.getItem('username');
    this.pageData.userinfo = JSON.parse(sessionStorage.getItem(this.pageData.username));
    this.pageData.lang = sessionStorage.getItem('lang');
    if (mountederjs.evaluateN(this)) {
      document.title = this.$t('title.activitylist');
      this.pageData.clientWidth = document.body.clientWidth;
      this.getActivities();
      setTimeout(() => { this.show.wholeShow = true }, 1);
    } else {
      this.$router.push({ name: 'Login' });
    }
  },
  methods: {
    setLanguage(command) {
      this.$i18n.locale = command;
      sessionStorage.setItem('lang', this.$i18n.locale);
      location.reload();
    },
    getActivities() {
      let params = {};
      params.username = this.pageData.username;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/quiz/queryActivityWhoParticipant',
        data: params
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.pageData.activitiesList = response.data.data;

            for (let activity of this.pageData.activitiesList) {
              if (activity.isreport === 1) {
                this.pageData.completeCount++;
              }
            }

            return;
          } else if (response.data.code == '210') {
            // this.$notify.success({
            //   title: this.$t("notify.title_notification"),
            //   message: this.$t('activitylist.notify_no_activity')
            // });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    reload() {
      location.reload();
    },
    goEvaluation(item) {
      // let targetAskForm = this.$router.resolve({
      //   name: 'AskForm', query: {
      //     username: this.pageData.username,
      //     questionnaire_id: item.questionnaire_id,
      //     relations_id: item.relations_id,
      //     actname: item.name,
      //     actname_en: item.name_en,
      //     fullname_obj: item.fullname,
      //     englishname_obj: item.englishname,
      //     username_obj: item.username
      //   }
      // });
      // window.open(targetAskForm.href, '_self');


      //fitter dingtalk
      this.$router.push({
        name: 'AskForm',
        query: {
          username: this.pageData.username,
          questionnaire_id: item.questionnaire_id,
          relations_id: item.relations_id,
          actname: item.name,
          actname_en: item.name_en,
          fullname_obj: item.fullname,
          englishname_obj: item.englishname,
          username_obj: item.username
        }
      });
    },
    openResultsDialog() {
      let params = {};
      params.username = this.pageData.username;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/quiz/queryActivitiesInParticipation',
        data: params
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.pageData.resultList = response.data.data;

            this.pageData.resultVisible = true;

            return;
          } else if (response.data.code == '210') {

            return;
          } else if (response.data.code == '701') {
            this.$notify.error({
              title: this.$t('notify.title_server'),
              message: this.$t('activitylist.notify_result_not_allow')
            });

            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    showResult(participation) {
      // let targetAskForm = this.$router.resolve({
      //   name: 'Result', query: {
      //     activity_id: participation.activity_id,
      //     participant: participation.username,
      //     questionnaire_id: participation.questionnaire_id
      //   }
      // });
      // window.open(targetAskForm.href, '_blank');

      this.$router.push({
        name: 'Result',
        query: {
          activity_id: participation.activity_id,
          participant: participation.username,
          questionnaire_id: participation.questionnaire_id
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.head_wrap {
  // display: flex;
  justify-content: end;
  padding: 15px;
  text-align: right;
}
.language_wrap {
  margin-right: 10px;
  vertical-align: middle;
}
.user_content {
  font-size: 15px;
  color: #606266;
  vertical-align: middle;
}
.complete_content {
  width: 90%;
  margin: 0 auto;
}
.main_wrap {
  width: 90%;
  margin: 0 auto;
  margin-top: 30px;
}
.avatar_dropdown_items_content a {
  text-decoration: none;
  color: #606266;
}
.activities_content {
  padding-top: 15px;
}
</style>